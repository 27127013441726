import Table, { TableCol } from 'modules/Layout/component/Table';
import React from 'react';
import Order from 'modules/ExecutorOrder/model/Order';
import { ORDERS_DELETE, ORDERS_DETAILS, ORDERS_EDIT } from 'modules/ExecutorOrder/permissions';
import Authorize from 'modules/Auth/container/Authorize';
import { ROUTE_ORDER, ROUTE_ORDER_UPDATE } from 'modules/ExecutorOrder/routes';
import { getPathUrl } from 'modules/Shared/helper/api';
import { Link } from 'react-router-dom';
import ActionUpdate from 'modules/Layout/component/Action/Update';
import { RootState } from 'app/reducer';
import { PartialSearchingProps } from 'modules/Shared/type';
import { useDispatch, useSelector } from 'react-redux';
import { setOrderListParamsAction } from 'modules/ExecutorOrder/action/list';
import ActionDelete from 'modules/Layout/component/Action/Delete';
import { formatDate, ifContainsDateFormat } from 'modules/Shared/helper/utils';
import EditIcon from 'modules/Layout/component/Icon/Edit';

type Props = {
  executor_id?: string | number;
  executorName?: string;
  onDeleteClick: (row: Order) => void;
};

const ExecutorOrdersTable: React.FC<Props> = ({ executor_id = 0, executorName, onDeleteClick }) => {
  const dispatch = useDispatch();
  const { orders, sort } = useSelector((state: RootState) => state.order.list);

  const setParams = (payload: PartialSearchingProps) => dispatch(setOrderListParamsAction(payload, executor_id));

  const redirectLink = (value: string, id: number) => (
    <Authorize permissions={[ORDERS_DETAILS]} fallback={value}>
      <Link
        className="fill-table-td"
        to={{
          pathname: getPathUrl(ROUTE_ORDER, { executor_id, id }),
          state: { executorName }
        }}
      >
        {value}
      </Link>
    </Authorize>
  );

  const cols: TableCol<Order>[] = [
    {
      property: 'name',
      label: 'Nazwa',
      sortable: true,
      value: (row) => redirectLink(row.name, row.id)
    },
    {
      property: 'principal_name',
      label: 'Nazwa zleceniodawcy',
      sortable: true,
      value: (row) => redirectLink(row.principal_name, row.id)
    },
    {
      property: 'contract_date',
      label: 'Data zawarcia umowy od',
      sortable: true,
      value: (row) => redirectLink(ifContainsDateFormat(row.contract_date, formatDate), row.id)
    },
    {
      property: 'actions',
      label: 'Akcje',
      value: function Actions(row) {
        return (
          <div className="actions-wrapper">
            <Authorize permissions={[ORDERS_EDIT]}>
              <ActionUpdate
                className="mx-1"
                title="Edycja postępowania"
                label={<EditIcon height="20px" />}
                to={{
                  pathname: getPathUrl(ROUTE_ORDER_UPDATE, { executor_id, id: row.id }),
                  state: { executorName }
                }}
              />
            </Authorize>
            {onDeleteClick && (
              <Authorize permissions={[ORDERS_DELETE]}>
                <ActionDelete className="mx-1" title="Usuń postępowanie" onClick={() => onDeleteClick(row)} />
              </Authorize>
            )}
          </div>
        );
      }
    }
  ];

  return <Table cols={cols} rows={orders} sort={sort} onSort={(params) => setParams({ sort: params })} />;
};

export default ExecutorOrdersTable;
