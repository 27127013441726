import { RootState } from 'app/reducer';
import { setSearchingParamsAction, SetSearchingParamsAction } from 'modules/Shared/action';
import Validation from 'modules/Shared/exception/Validation';
import { createSearchParams } from 'modules/Shared/helper/params';
import { PaginationMeta, PartialSearchingProps } from 'modules/Shared/type';
import { OfficeEntity } from 'modules/Office/model/Office';
import { fetchOffices } from 'modules/Office/repository';
import { Action, Dispatch } from 'redux';
import _ from 'lodash';

export const SET_OFFICE_LIST_PARAMS = 'OFFICE/LIST/PARAMS/SET';
export const OFFICE_LIST = 'OFFICE/LIST';

export type OfficeListAction = SetOfficeListParamsAction | OfficeListFetchedAction;

export type SetOfficeListParamsAction = SetSearchingParamsAction<typeof SET_OFFICE_LIST_PARAMS>;

export type OfficeListFetchedPayload = {
  offices: OfficeEntity[];
  meta: PaginationMeta;
};
export interface OfficeListFetchedAction extends Action<typeof OFFICE_LIST> {
  payload: OfficeListFetchedPayload;
}
export const setOfficeListParamsAction =
  (payload: PartialSearchingProps) => async (dispatch: Dispatch, getState: () => RootState) => {
    const currentState = getState()?.office?.list;

    if (
      payload?.filter &&
      !payload?.pagination &&
      typeof currentState?.pagination?.page === 'number' &&
      currentState.pagination.page !== 1 &&
      !_.isEqual(currentState.filter, payload.filter)
    ) {
      currentState.pagination.page = 1;
    }

    const state = { ...currentState, ...payload };

    dispatch(setSearchingParamsAction(SET_OFFICE_LIST_PARAMS, payload));
    try {
      const { pagination, filter, sort } = state;

      const searchParams = createSearchParams({ pagination, filter, sort });

      const response = await fetchOffices(searchParams);

      const { data, meta } = response.data;

      dispatch<OfficeListFetchedAction>({
        type: OFFICE_LIST,
        payload: { offices: data, meta }
      });
    } catch (error) {
      if (error instanceof Validation) {
        dispatch<OfficeListFetchedAction>({
          type: OFFICE_LIST,
          payload: { offices: [], meta: null }
        });
      } else {
        throw error;
      }
    }
  };
