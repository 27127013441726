import { ClauseFieldValues, ClauseFieldValuesState } from 'modules/Clause/type';
import { suffixLabel } from 'modules/Layout/helper/misc';
import { getError, hasError } from 'modules/Shared/helper/validation';
import { ValidationErrors } from 'modules/Shared/type';
import React from 'react';
import { FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import ClauseCategoryAutocomplete from 'modules/Clause/container/ClauseAutocomplete/Category';
import _uniqueId from 'lodash/uniqueId';
import JudgementKeywordsInputAutocomplete from 'modules/Judgement/container/JudgementAutocomplete/KeywordsInput';

export interface Props {
  values: ClauseFieldValues & { category?: { name: string; id: number } };
  displayWysiwyg: () => JSX.Element;
  errors?: ValidationErrors;
  disabled?: boolean;
  required?: boolean;
  onChange: (value: ClauseFieldValuesState[keyof ClauseFieldValuesState], key: string) => void;
}

const ClauseFieldset: React.FC<Props> = (props: Props): JSX.Element => {
  const { values, displayWysiwyg, errors, disabled, onChange, required } = props;
  const { name, category, keywords } = values;
  //  w związku z taskiem 1177 słowa kluczowe są połączone z orzeczeniami

  // const [keywordValue, setKeywordValue] = useState('');
  // const [keywordValueError, setKeywordValueError] = useState('');

  // const convertKeywords = keywords.map((keyword) => ({ id: (_uniqueId() as unknown) as number, content: keyword }));
  // const onDeleteClicked = (id: number | string) => {
  //   convertKeywords.splice(
  //     convertKeywords.findIndex((obj) => obj.id === id),
  //     1
  //   );

  //   onChange(
  //     convertKeywords.map((convObj) => convObj.content),
  //     'keywords'
  //   );

  //   if (keywordValueError && !convertKeywords.map((val) => val.content).includes(keywordValue))
  //     setKeywordValueError('');
  // };

  // const onKeywordValueChange = (inputValue: string) => {
  //   if (keywordValueError) {
  //     setKeywordValueError('');
  //   }

  //   setKeywordValue(inputValue);
  // };

  // useEffect(() => {
  //   if (keywordValueError) {
  //     setKeywordValueError('');
  //   }
  //   // eslint-disable-next-line
  // }, [keywords]);

  // const onKeywordInputBtnPressed = (listElementValue?: string) => {
  //   if (!keywords.includes(listElementValue ?? keywordValue)) {
  //     onChange([...keywords, listElementValue ?? keywordValue], 'keywords');
  //   } else {
  //     setKeywordValueError('Słowo kluczowe już jest dodane.');
  //   }
  //   setKeywordValue('');
  // };

  const onCategoryChange = (categoryValue: { name: string; id: number }) => onChange(categoryValue, 'category');
  const onKeywordChange = (keywordsValue: number[]) => onChange(keywordsValue, 'keywords');

  return (
    <fieldset className="m-0" disabled={disabled}>
      <FormGroup>
        <Label for="input-name">{suffixLabel('Nazwa klauzuli', required)}</Label>
        <Input
          type="text"
          id="input-name"
          value={name}
          onChange={(event) => onChange(event.target.value, 'name')}
          invalid={hasError(errors, 'name')}
          maxLength={500}
          required={required}
        />
        {hasError(errors, 'name') && <FormFeedback>{getError(errors, 'name')}</FormFeedback>}
      </FormGroup>

      <ClauseCategoryAutocomplete category={category} onCategoryChange={onCategoryChange} required />

      {/* <ClauseKeywordsInputAutocomplete
        value={keywordValue}
        setValue={onKeywordValueChange}
        initKeywordValues={keywords}
        onInputButtonPressed={onKeywordInputBtnPressed}
        keywordErrorInput={keywordValueError}
        hideWhenEmptyInput
        chipContentElements={convertKeywords}
        onDeleteChipElement={onDeleteClicked}
      /> */}
      <JudgementKeywordsInputAutocomplete keywords={keywords} onKeywordsChange={onKeywordChange} />

      <FormGroup>
        {displayWysiwyg && displayWysiwyg()}

        {hasError(errors, 'description') && (
          <FormFeedback className="d-block">{getError(errors, 'description')}</FormFeedback>
        )}
      </FormGroup>
    </fieldset>
  );
};

export default ClauseFieldset;
