import React from 'react';
import { RootState } from 'app/reducer';
import ListingPerPage from 'modules/Layout/component/Listing/PerPage';
import { PartialSearchingProps } from 'modules/Shared/type';
import { useDispatch, useSelector } from 'react-redux';
import { setForeignProceedingsListParamsAction } from '../../../action/ForeignProceedingsList';

const ForeignProceedingsPerPage = () => {
  const dispatch = useDispatch();
  const { meta } = useSelector((state: RootState) => state.foreignProceedings.foreignProceedingsList);
  const setParams = (payload: PartialSearchingProps) => dispatch(setForeignProceedingsListParamsAction(payload));

  return <ListingPerPage meta={meta} setParams={setParams} />;
};

export default ForeignProceedingsPerPage;
