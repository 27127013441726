export type PenaltiesFileEntity = {
  id?: number;
  section_slug?: string;
  name?: string;
  created_at?: string;
  updated_at?: string;
};

export type OrderEntity = {
  id?: number;
  name?: string;
  identifier?: string;
  principal_name?: string;
  principal_address?: string;
  contract_number?: string;
  contract_place?: string;
  contract_value?: number;
  contract_date?: string;
  notice_number?: string;
  penalties_checked?: boolean;
  executor_salary?: number;
  penalty_amount?: number;
  penalty_date?: string;
  penalty_description?: string;
  termination_checked?: boolean;
  termination_description?: string;
  termination_date?: string;
  complaint_checked?: boolean;
  complaint_description?: string;
  complaint_date?: string;
  complaint_identifier?: string;
  complaint_result?: string;
  misrepresentation_checked?: boolean;
  exclusion_description?: string;
  exclusion_date?: string;
  exclusion_identifier?: string;
  has_problems?: boolean;
  penalties_files?: PenaltiesFileEntity[];
  termination_files?: PenaltiesFileEntity[];
  complaint_files?: PenaltiesFileEntity[];
  misrepresentation_files?: PenaltiesFileEntity[];
};

export default class Order {
  readonly id?: number;
  readonly name?: string;
  readonly identifier?: string;
  readonly principal_name?: string;
  readonly principal_address?: string;
  readonly contract_number?: string;
  readonly contract_place?: string;
  readonly contract_value?: number;
  readonly contract_date?: string;
  readonly notice_number?: string;
  readonly penalties_checked?: boolean;
  readonly executor_salary?: number;
  readonly penalty_amount?: number;
  readonly penalty_date?: string;
  readonly penalty_description?: string;
  readonly termination_checked?: boolean;
  readonly termination_description?: string;
  readonly termination_date?: string;
  readonly complaint_checked?: boolean;
  readonly complaint_description?: string;
  readonly complaint_date?: string;
  readonly complaint_identifier?: string;
  readonly complaint_result?: string;
  readonly misrepresentation_checked?: boolean;
  readonly exclusion_description?: string;
  readonly exclusion_date?: string;
  readonly exclusion_identifier?: string;
  readonly has_problems?: boolean;
  readonly penalties_files?: PenaltiesFileEntity[];
  readonly termination_files?: PenaltiesFileEntity[];
  readonly complaint_files?: PenaltiesFileEntity[];
  readonly misrepresentation_files?: PenaltiesFileEntity[];

  constructor(entity: OrderEntity) {
    this.id = entity.id;
    this.name = entity.name;
    this.identifier = entity.identifier;
    this.principal_name = entity.principal_name;
    this.principal_address = entity.principal_address;
    this.contract_number = entity.contract_number;
    this.contract_place = entity.contract_place;
    this.contract_value = entity.contract_value;
    this.contract_date = entity.contract_date;
    this.notice_number = entity.notice_number;
    this.penalties_checked = entity.penalties_checked;
    this.executor_salary = entity.executor_salary;
    this.penalty_amount = entity.penalty_amount;
    this.penalty_date = entity.penalty_date;
    this.penalty_description = entity.penalty_description;
    this.termination_checked = entity.termination_checked;
    this.termination_description = entity.termination_description;
    this.termination_date = entity.termination_date;
    this.complaint_checked = entity.complaint_checked;
    this.complaint_description = entity.complaint_description;
    this.complaint_date = entity.complaint_date;
    this.complaint_identifier = entity.complaint_identifier;
    this.complaint_result = entity.complaint_result;
    this.misrepresentation_checked = entity.misrepresentation_checked;
    this.exclusion_description = entity.exclusion_description;
    this.exclusion_date = entity.exclusion_date;
    this.exclusion_identifier = entity.exclusion_identifier;
    this.has_problems = entity.has_problems;
    this.penalties_files = entity.penalties_files;
    this.termination_files = entity.termination_files;
    this.complaint_files = entity.complaint_files;
    this.misrepresentation_files = entity.misrepresentation_files;
  }

  static getFilterableAttributes(): string[] {
    return ['name', 'pricipal_name', 'contract_date_from', 'contract_date_to '];
  }

  static getSortableAttributes(): string[] {
    return ['name', 'pricipal_name', 'contract_date'];
  }
}

export const createOrder = (entity?: OrderEntity): Order => new Order(entity);

export const PENALTIES_SLUG = 'penalties';
export const TERMINATION_SLUG = 'termination';
export const COMPLAINT_SLUG = 'complaint';
export const MISREPRESENTATION_SLUG = 'misrepresentation';

export const addFilesViolationTitle = (slug: string) => {
  switch (slug) {
    case PENALTIES_SLUG: {
      return 'Dodawanie plików do kar umownych';
    }
    case TERMINATION_SLUG: {
      return 'Dodawanie plików do rozwiązanych umów';
    }
    case COMPLAINT_SLUG: {
      return 'Dodawanie plików do wytoczych powództw';
    }
    case MISREPRESENTATION_SLUG: {
      return 'Dodawanie plików do wprowadzenia zamawiającego w błąd';
    }
    default:
      return '';
  }
};
