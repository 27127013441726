import React from 'react';
import { PAYMENY_HISTORY_ALL, USERS_ADD, USERS_INDEX } from 'modules/User/permissions';
import {
  ROUTE_USER_CREATE,
  ROUTE_USERS,
  ROUTE_USERS_ADMINS,
  ROUTE_USER_ADMINS_CREATE,
  ROUTE_USERS_DELETED,
  ROUTE_INVOICES
} from 'modules/User/routes';
import { ROUTE_TRADITIONAL_PAYMENTS_WAITING } from 'modules/Subscriptions/routes';
import { Menu } from 'app/menu';
import ClientIcon from 'modules/Layout/component/Icon/Client';
import AdminIcon from 'modules/Layout/component/Icon/Admin';
import User from 'modules/User/model/User';
import { ROLE_ADMIN } from 'modules/User/model/UserRole';
import { SUBSCRIPTIONS_MODULE_ACCESS } from '../Auth/container/Guard/permissions';

const MenuUserShared: Menu[] = [
  {
    key: 'user-section',
    type: 'section',
    title: 'Administracja',
    hasAccess: (user: User): boolean => user?.role?.slug === ROLE_ADMIN
  },
  {
    key: 'users-nested',
    type: 'nested',
    title: 'Klienci',
    icon: <ClientIcon />,
    navLevel: 'second',
    href: '',
    isActive: () => false,
    permissions: [USERS_INDEX, USERS_ADD],
    children: [
      {
        key: 'users-client-index',
        type: 'link',
        title: 'Lista aktywnych',
        href: ROUTE_USERS,
        permissions: [USERS_INDEX]
      },
      {
        key: 'users-client-index-deleted',
        type: 'link',
        title: 'Lista usuniętych',
        href: ROUTE_USERS_DELETED,
        permissions: [USERS_INDEX]
      },
      {
        key: 'traditional-payments-waiting-index',
        type: 'link',
        title: 'Prośba o FV',
        href: ROUTE_TRADITIONAL_PAYMENTS_WAITING,
        hasAccess: (user: User) => user.hasPermissions([PAYMENY_HISTORY_ALL, SUBSCRIPTIONS_MODULE_ACCESS]),
        permissions: [USERS_INDEX, SUBSCRIPTIONS_MODULE_ACCESS]
      },
      {
        key: 'users-client-create',
        type: 'link',
        title: 'Dodaj',
        href: ROUTE_USER_CREATE,
        permissions: [USERS_ADD]
      },
      {
        key: 'invoices-required-index',
        type: 'link',
        title: 'Faktury',
        href: ROUTE_INVOICES,
        hasAccess: (user: User) => user.hasPermissions([PAYMENY_HISTORY_ALL, SUBSCRIPTIONS_MODULE_ACCESS]),
        permissions: [PAYMENY_HISTORY_ALL, SUBSCRIPTIONS_MODULE_ACCESS]
      }
    ]
  },
  {
    key: 'users-admin-nested',
    type: 'nested',
    title: 'Administratorzy',
    navLevel: 'second',
    icon: <AdminIcon />,
    href: '',
    permissions: [USERS_INDEX, USERS_ADD],
    isActive: () => false,
    children: [
      {
        key: 'users-admin-index',
        type: 'link',
        title: 'Lista',
        href: ROUTE_USERS_ADMINS,
        permissions: [USERS_INDEX]
      },
      {
        key: 'users-admin-create',
        type: 'link',
        title: 'Dodaj',
        href: ROUTE_USER_ADMINS_CREATE,
        permissions: [USERS_ADD]
      }
    ]
  }
];

/*
import * as H from 'history';

key: 'users-nested',
    type: 'nested',
    title: 'Użytkownicy',
    icon: 'remixicon-user-fill',
    navLevel: 'second',
    href: ROUTE_USERS,
    permissions: [USERS_INDEX, USERS_ADD],
    isActive: (_match: null, location: H.Location) => location.pathname.includes('users'),
    children: [
      {
        key: 'users-index',
        type: 'link',
        title: 'Lista',
        href: ROUTE_USERS,
        permissions: [USERS_INDEX]
      },
      {
        key: 'users-create',
        type: 'link',
        title: 'Dodaj',
        href: ROUTE_USER_CREATE,
        permissions: [USERS_ADD]
      }
    ]

*/

export default MenuUserShared;
