import axios, { AxiosPromise } from 'axios';
import { getAPIUrl } from 'modules/Shared/helper/api';
import { PaginationMeta, SearchParams } from 'modules/Shared/type';
import { ExecutorEntity } from 'modules/Executor/model/Executor';
import { ExecutorClientEntity } from 'modules/Executor/model/ExecutorClient';
import {
  ExecutorChangesValues,
  ExecutorOrderChangesValues,
  ExecutorExplanationSectionValues
} from 'modules/Executor/type';

export const API_EXECUTOR_ADMIN = '/api/admin/executors';
export const API_EXECUTOR_ADMIN_CRUD = '/api/admin/executors/:id';
export const API_EXECUTOR = '/api/executors';
export const API_EXECUTOR_DETAILS = '/api/executors/:id';
export const API_EXECUTOR_GUS = '/api/api-gus/:nip';
export const API_EXECUTOR_USE_CREDIT = '/api/executors/use_credit';
export const API_EXECUTOR_DOWNLOAD_PDF = '/api/executors/:id/report';
export const API_EXECUTOR_CHANGES = '/api/admin/executors/:id/audits';
export const API_EXECUTOR_ORDER_CHANGES = '/api/orders/:id/audits';
export const API_EXECUTOR_REPORT_SECTION = '/api/executors/report_sections';

export type FetchExecutorsResponse = {
  data: ExecutorEntity[];
  meta: PaginationMeta;
  links: any;
};

export const fetchAdminExecutors = (params: SearchParams = {}): AxiosPromise<FetchExecutorsResponse> =>
  axios.get(getAPIUrl(API_EXECUTOR_ADMIN, null, params));

export type FetchExecutorResponse = {
  data: ExecutorEntity;
};

export const fetchAdminExecutor = (id: number | string, cancelToken?: any): AxiosPromise<FetchExecutorResponse> =>
  axios.get(getAPIUrl(API_EXECUTOR_ADMIN_CRUD, { id }), { cancelToken });

export const createAdminExecutor = (data: ExecutorEntity, cancelToken?: any): AxiosPromise<FetchExecutorResponse> =>
  axios.post(getAPIUrl(API_EXECUTOR_ADMIN), data, { cancelToken });

export const updateAdminExecutor = (
  id: number | string,
  data: ExecutorEntity,
  cancelToken?: any
): AxiosPromise<FetchExecutorResponse> => axios.put(getAPIUrl(API_EXECUTOR_ADMIN_CRUD, { id }), data, { cancelToken });

export const deleteAdminExecutor = (id: number | string, cancelToken?: any): AxiosPromise<FetchExecutorResponse> =>
  axios.delete(getAPIUrl(API_EXECUTOR_ADMIN_CRUD, { id }), { cancelToken });

export type FetchExecutorsClientResponse = {
  data: ExecutorClientEntity[];
  meta: PaginationMeta;
  links: any;
};

export type FetchExecutorClientResponse = {
  data: ExecutorClientEntity;
};

export const fetchExecutors = (params: SearchParams = {}): AxiosPromise<FetchExecutorsClientResponse> =>
  axios.get(getAPIUrl(API_EXECUTOR, null, params));

export const fetchExecutor = (id: number | string, cancelToken?: any): AxiosPromise<FetchExecutorClientResponse> =>
  axios.get(getAPIUrl(API_EXECUTOR_DETAILS, { id }), { cancelToken });

export const fetchExecutorFromGus = (nip: string): AxiosPromise<any> => axios.get(getAPIUrl(API_EXECUTOR_GUS, { nip }));

export const useCreditExecutor = (
  data: { date_to: string; nip: string; foreign_nip?: boolean },
  cancelToken?: any
): AxiosPromise<{ data: { executor_id: number } }> =>
  axios.post(getAPIUrl(API_EXECUTOR_USE_CREDIT), data, { cancelToken });

export const fetchExecutorRaport = (id: string | number, cancelToken?: any): AxiosPromise =>
  axios.get(getAPIUrl(API_EXECUTOR_DOWNLOAD_PDF, { id }), {
    responseType: 'blob',
    cancelToken
  });

export type ChangesExecutorListResponse = {
  data: ExecutorChangesValues[];
  meta: PaginationMeta;
  links: any;
};

export const fetchChangesExecutorList = (
  id: string | number,
  params: SearchParams = {},
  cancelToken?: any
): AxiosPromise<ChangesExecutorListResponse> =>
  axios.get(getAPIUrl(API_EXECUTOR_CHANGES, { id }, params), { cancelToken });

export type ChangesExecutorOrderListResponse = {
  data: ExecutorOrderChangesValues[];
  meta: PaginationMeta;
  links: any;
};

export const fetchChangesExecutorOrderList = (
  id: string | number,
  params: SearchParams = {},
  cancelToken?: any
): AxiosPromise<ChangesExecutorOrderListResponse> =>
  axios.get(getAPIUrl(API_EXECUTOR_ORDER_CHANGES, { id }, params), { cancelToken });

export const fetchExecutorExplanationSection = (
  cancelToken?: any
): AxiosPromise<{ data: ExecutorExplanationSectionValues[] }> =>
  axios.get(getAPIUrl(API_EXECUTOR_REPORT_SECTION), { cancelToken });

export const updateExecutorExplanationSection = (
  data: { sections: ExecutorExplanationSectionValues[] },
  cancelToken?: any
): AxiosPromise<{ data: ExecutorExplanationSectionValues[] }> =>
  axios.put(getAPIUrl(API_EXECUTOR_REPORT_SECTION), data, { cancelToken });
