import { correctCredisDescriptionForm } from 'modules/Executor/view/client';
import Date from 'modules/Layout/component/Input/Date';
import { getError, hasError } from 'modules/Shared/helper/validation';
import { ValidationErrors } from 'modules/Shared/type';
import React from 'react';
import { FormFeedback, FormGroup, Input, Label } from 'reactstrap';

export type IModuleValues = {
  date_to: string;
  credits?: number;
};

export type Props = {
  moduleValues: IModuleValues;
  setModuleValues?: React.Dispatch<React.SetStateAction<IModuleValues>>;
  minCreditsValue: number;
  errors?: ValidationErrors;
};

const FieldsetModules: React.FC<Props> = (props: Props): JSX.Element => {
  const { moduleValues, setModuleValues, minCreditsValue, errors } = props;

  const setCreditNumber = (creditsFromEvent: string) => {
    const credits = parseInt(creditsFromEvent, 10);
    if (Number.isNaN(credits)) setModuleValues({ ...moduleValues, credits: undefined });
    else setModuleValues({ ...moduleValues, credits });
  };

  return (
    <>
      <FormGroup>
        <Label for="date_to">Data ważności</Label>
        <Date
          name="date_to"
          id="date_to"
          value={moduleValues.date_to ?? ''}
          min=""
          max=""
          onChange={(val) => setModuleValues({ ...moduleValues, date_to: val })}
          invalid={hasError(errors, 'date_to')}
          required
        />
        {hasError(errors, 'date_to') && <FormFeedback className="d-block">{getError(errors, 'date_to')}</FormFeedback>}
      </FormGroup>
      {moduleValues?.credits !== null && (
        <FormGroup>
          <Label for="credits">Ilość</Label>
          <div className="d-flex">
            <Input
              type="number"
              name="credits"
              id="credits"
              value={moduleValues.credits ?? ''}
              onChange={(event) => setCreditNumber(event.target.value)}
              min={minCreditsValue}
              invalid={hasError(errors, 'credits')}
              required
            />
          </div>
          <div className="modal-body-text">
            Podana powyżej wartość jest bazową ilością kredytów.{' '}
            {!!minCreditsValue && `Zużyto już ${minCreditsValue} ${correctCredisDescriptionForm(minCreditsValue)}.`}
          </div>
          {hasError(errors, 'credits') && (
            <FormFeedback className="d-block">{getError(errors, 'credits')}</FormFeedback>
          )}
        </FormGroup>
      )}
    </>
  );
};

export default FieldsetModules;
