import React, { useState } from 'react';
import ViolationFilesTable from 'modules/ExecutorOrder/container/ViolationFilesTable';
import Listing from 'modules/Layout/component/Listing';
import { PenaltiesFileEntity } from 'modules/ExecutorOrder/model/Order';
import Loader from 'modules/Layout/component/Loader';
import { useDispatch } from 'react-redux';
import { addToastAction } from 'modules/Layout/action';
import serverErrorToast from 'modules/Shared/action/errorToast';
import fetchFileRequest from 'modules/Shared/helper/APIRequests/fetchFileRequest';
import { orderFileDownloadToastSuccess } from 'modules/ExecutorOrder/toasts';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import { fetchOrderFile } from 'modules/ExecutorOrder/repository';

type Props = {
  orderFiles: PenaltiesFileEntity[];
  executorId: number;
  orderId: number;
  orderListStyle?: { [key: string]: string };
};

const DisplayExecutorOrderList: React.FC<Props> = ({
  executorId,
  orderId,
  children,
  orderListStyle = {},
  ...props
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const dispatchErrorMessageToast = (errMessage: string) =>
    dispatch(addToastAction(serverErrorToast(errMessage, 'Błąd zarządzania plikami')));

  const dispatchOrderFileDownloadToast = () => dispatch(addToastAction(orderFileDownloadToastSuccess()));

  const cancelToken = useCancelToken();
  const orderDownloadFileRequest = async (fileId: number | string) => {
    if (loading) return;

    setLoading(true);
    const {
      cancelled,
      message: messageRes,
      success
    } = await fetchFileRequest(fetchOrderFile, executorId, orderId, fileId, cancelToken);

    if (cancelled) return;
    if (success) dispatchOrderFileDownloadToast();
    if (messageRes) {
      dispatchErrorMessageToast(messageRes.value);
    }
    setLoading(false);
  };

  return (
    <div className="display-executor-order-list" style={orderListStyle}>
      {children}
      {!!props?.orderFiles?.length && (
        <>
          <div className="label">Załączniki</div>
          <div className="position-relative">
            {loading && <Loader />}
            <Listing table={<ViolationFilesTable orderDownloadFileRequest={orderDownloadFileRequest} {...props} />} />
          </div>
        </>
      )}
    </div>
  );
};

export default DisplayExecutorOrderList;
