import React from 'react';
import { RootState } from 'app/reducer';
import Listing from 'modules/Layout/component/Listing';
import { useDispatch, useSelector } from 'react-redux';
import { addToastAction } from 'modules/Layout/action';
import { updateProceedingsFavouriteAction } from 'modules/Proceedings/action/ProceedingsList';
import ProceedingsTable from 'modules/Proceedings/container/ProceedingsList/Table';
import ProceedingsPerPage from 'modules/Proceedings/container/ProceedingsList/PerPage';
import ProceedingsListingPagination from 'modules/Proceedings/container/ProceedingsList/Pagination';
import {
  addProceedingsToFavouriteToastSuccess,
  removeProceedingsFromFavouriteToastSuccess
} from 'modules/Proceedings/toasts';
import ProceedingsClientFilter from 'modules/Proceedings/container/ProceedingsList/Filter/clientFilter';
import logo from 'modules/Layout/component/ProceedingsListLogo/baner-desktop.jpg';

const useClientProceedingsList = (proceedingsRoute: string, proceedingsType: number) => {
  const dispatch = useDispatch();
  const { fetching } = useSelector((state: RootState) => state.proceedings.proceedingsList);

  const dispatchAddProceedingsFavToast = () => dispatch(addToastAction(addProceedingsToFavouriteToastSuccess()));
  const dispatchRemoveProceedingsFavToast = () =>
    dispatch(addToastAction(removeProceedingsFromFavouriteToastSuccess()));

  const manageProceedingsFavouriteAction = (proceeding_id: number, isAdding?: boolean) => {
    const toastFnc = isAdding ? dispatchAddProceedingsFavToast : dispatchRemoveProceedingsFavToast;
    dispatch(updateProceedingsFavouriteAction(proceeding_id, toastFnc));
  };

  const templateTheme = process.env.THEME_TEMPLATE as 'pzp' | 'bs';

  const jsxFnc = () => (
    <div className="row users-view">
      <div className="col-12">
        {templateTheme === 'pzp' && (
          <div className="mb-3">
            <a
              href="https://postepowania.pl/baza-wykonawcow-w-stosunku-do-ktorych-moga-wystapic-podstawy-do-wykluczenia/"
              target="_blank"
            >
              <img className="mw-100" src={logo} alt="Baner Sprawdź swoją konkurencię w przetargach!" />
            </a>
          </div>
        )}
        <Listing
          table={
            <ProceedingsTable
              isAdmin={false}
              manageFavouriteAction={manageProceedingsFavouriteAction}
              proceedingsType={proceedingsType}
            />
          }
          filter={<ProceedingsClientFilter proceedingsType={proceedingsType} />}
          pagination={<ProceedingsListingPagination path={proceedingsRoute} />}
          perPage={<ProceedingsPerPage />}
          loading={fetching}
        />
      </div>
    </div>
  );

  return { jsxFnc };
};

export default useClientProceedingsList;
