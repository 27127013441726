import React from 'react';
import InformationList from 'modules/Layout/component/InformationList';
import Order from 'modules/ExecutorOrder/model/Order';
import { formatDate, ifContainsDateFormat } from 'modules/Shared/helper/utils';

export interface Props {
  order?: Order;
}

const MisrepresentationProblem: React.FC<Props> = ({ order = {} }) => {
  const fields = [
    {
      label: 'Przyczyny wykluczenia z postępowania',
      value: order.exclusion_description ?? 'nie dotyczy',
      html: true
    },
    {
      label: 'Data wykluczenia',
      value: ifContainsDateFormat(order.exclusion_date, formatDate) ?? 'nie dotyczy'
    },
    {
      label: 'Sygnatura wyroku Krajowej Izby Odwoławczej',
      value: order.exclusion_identifier ?? 'nie dotyczy'
    }
  ];

  return <InformationList fields={fields} />;
};

export default MisrepresentationProblem;
