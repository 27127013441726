import { connectRouter, RouterState } from 'connected-react-router';
import { History } from 'history';
import { combineReducers, Reducer } from 'redux';
import authReducer from 'modules/Auth/reducer';
import { AuthState } from 'modules/Auth/state';
import layoutReducer from 'modules/Layout/reducer';
import { LayoutState } from 'modules/Layout/state';
import userReducer from 'modules/User/reducer';
import { UserState } from 'modules/User/state';
import profileReducer from 'modules/Profile/reducer';
import { ProfileState } from 'modules/Profile/state';
import SubscriptionReducer from 'modules/Subscriptions/reducer';
import { SubscriptionState } from 'modules/Subscriptions/state';
import ExecutorReducer from 'modules/Executor/reducer';
import { ExecutorState } from 'modules/Executor/state';
import OfficeReducer from 'modules/Office/reducer';
import { OfficeState } from 'modules/Office/state';
import OrderReducer from 'modules/ExecutorOrder/reducer';
import { OrderState } from 'modules/ExecutorOrder/state';
import JudgementReducer from 'modules/Judgement/reducer';
import { JudgementState } from 'modules/Judgement/state';
import ClauseReducer from 'modules/Clause/reducer';
import { ClauseState } from 'modules/Clause/state';
import JudgementClientReducer from 'modules/JudgementClient/reducer';
import { JudgementClientState } from 'modules/JudgementClient/state';
import ProceedingsReducer from 'modules/Proceedings/reducer';
import ForeignProceedingsReducer from 'modules/ForeignProceedings/reducer';
import { ProceedingsState } from 'modules/Proceedings/state';
import AgreementsReducer from 'modules/Agreements/reducer';
import { AgreementsState } from 'modules/Agreements/state';
import { ForeignProceedingsState } from '../modules/ForeignProceedings/state';

export type RootState = {
  router: RouterState;
  auth: AuthState;
  layout: LayoutState;
  user: UserState;
  profile: ProfileState;
  subscriptions: SubscriptionState;
  executor: ExecutorState;
  office: OfficeState;
  order: OrderState;
  judgement: JudgementState;
  clause: ClauseState;
  judgmentClient: JudgementClientState;
  proceedings: ProceedingsState;
  foreignProceedings: ForeignProceedingsState;
  agreements: AgreementsState;
};

const createRootReducer = (history: History): Reducer => {
  const reducers = {
    auth: authReducer,
    layout: layoutReducer,
    router: connectRouter(history),
    user: userReducer(),
    profile: profileReducer,
    subscriptions: SubscriptionReducer(),
    executor: ExecutorReducer(),
    office: OfficeReducer(),
    order: OrderReducer(),
    judgement: JudgementReducer(),
    clause: ClauseReducer(),
    judgmentClient: JudgementClientReducer(),
    proceedings: ProceedingsReducer(),
    foreignProceedings: ForeignProceedingsReducer(),
    agreements: AgreementsReducer()
  };

  return combineReducers<RootState>(reducers);
};

export default createRootReducer;
