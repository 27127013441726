import _isEmpty from 'lodash/isEmpty';
import { useDispatch } from 'react-redux';
import { ExecutorEntity } from 'modules/Executor/model/Executor';
import { suffixLabel } from 'modules/Layout/helper/misc';
import { getError, hasError } from 'modules/Shared/helper/validation';
import { ValidationErrors } from 'modules/Shared/type';
import React, { useState } from 'react';
import moment from 'moment';
import { Button, CustomInput, FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import { DATE_FORMAT_SLASH, DATE_FORMAT_VALUE } from 'modules/Shared/helper/utils';
import { fetchExecutorFromGus } from 'modules/Executor/repository';
import { addToastAction } from 'modules/Layout/action';
import { executorFromGusToastSuccess, executorFromGusToastError } from 'modules/Executor/toasts';
import DisplayPopovers from 'modules/Layout/component/Popover/DisplayWrapper';

export interface Props {
  values: ExecutorEntity;
  errors?: ValidationErrors;
  disabled?: boolean;
  required?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>, key: keyof ExecutorEntity) => void;
  onForeignNipCheckboxChange: () => void;
  placeholder?: boolean;
  setMultipleValues: (data: Record<any, any>) => void;
  executorFromGus: Record<any, any>;
  setExecutorFromGus: (data: Record<any, any>) => void;
}

const ExecutorFieldset: React.FC<Props> = (props: Props): JSX.Element => {
  const dispatch = useDispatch();
  const {
    values,
    errors,
    disabled,
    onChange,
    onForeignNipCheckboxChange,
    placeholder = false,
    required,
    setMultipleValues,
    executorFromGus,
    setExecutorFromGus
  } = props;
  const [fillByHandStatus, setFillByHandStatus] = useState<boolean>(!!values.name);
  const [dataCorrectnessStatus, setDataCorrectnessStatus] = useState<boolean>(false);

  const {
    name,
    first_name = '',
    last_name = '',
    email = '',
    phone = '',
    nip = '',
    foreign_nip = false,
    city = '',
    voivodeship = '',
    street = '',
    business_start_date = '',
    krs = '',
    postal_code = '',
    post_office = ''
  } = values;

  const fetchExecutor = async (): Promise<any> => {
    try {
      const {
        data: { data }
      } = await fetchExecutorFromGus(values.nip);

      if (_isEmpty(data)) {
        dispatch(addToastAction(executorFromGusToastError()));
        return null;
      }

      dispatch(addToastAction(executorFromGusToastSuccess()));

      let { business_start_date } = data[0];

      if (business_start_date) {
        const momentObj = moment(business_start_date, DATE_FORMAT_VALUE, true);
        if (!momentObj.isValid()) return;

        data[0] = {
          ...data[0],
          business_start_date: momentObj.format(DATE_FORMAT_SLASH)
        };
      }

      setMultipleValues(data[0]);
      setExecutorFromGus(data[0]);
    } catch (error) {
      throw error;
    }
  };

  return (
    <fieldset className="m-0" disabled={disabled}>
      <FormGroup>
        {!placeholder && <Label for="input-nip">NIP</Label>}
        <div className="d-flex align-items-center">
          <div className="d-flex flex-column w-100">
            <Input
              type={foreign_nip ? 'text' : 'number'}
              name="nip"
              id="input-nip"
              placeholder={placeholder ? 'NIP' : null}
              value={nip}
              onChange={(event) => onChange(event, 'nip')}
              invalid={hasError(errors, 'nip')}
              className="mr-1"
            />
            {hasError(errors, 'nip') && <FormFeedback>{getError(errors, 'nip')}</FormFeedback>}
          </div>
          <DisplayPopovers
            popoverId="popover_nip"
            popoverContent="Dane są automatycznie pobierane z rejestru GUS"
            className="tooltip-light"
          />
        </div>
      </FormGroup>
      <FormGroup>
        <div className="d-flex flex-wrap align-items-center">
          <CustomInput
            id="foreign_nip"
            type="checkbox"
            label="NIP firmy zagranicznej (zezwolenie użycia wartości alfabetycznych)"
            checked={foreign_nip}
            onChange={onForeignNipCheckboxChange}
          />
        </div>
      </FormGroup>
      <div className="w-100">
        {!fillByHandStatus && executorFromGus === null && (
          <Button
            type="button"
            color="primary"
            className="pzpeu-btn-disabled waves-effect waves-light w-100"
            disabled={!values.nip}
            onClick={fetchExecutor}
          >
            Wyszukaj
          </Button>
        )}
        {executorFromGus === null && (
          <FormGroup>
            <CustomInput
              id="fill_by_hand"
              type="checkbox"
              name="fill_by_hand"
              label="Wprowadź dane ręcznie."
              className="mt-4"
              checked={fillByHandStatus}
              onChange={(event) => setFillByHandStatus(event.target.checked)}
            />
          </FormGroup>
        )}
      </div>
      {(fillByHandStatus || executorFromGus) && [
        <FormGroup>
          {!placeholder && <Label for="input-name">{suffixLabel('Nazwa', required)}</Label>}
          <Input
            type="text"
            name="name"
            id="input-name"
            placeholder={placeholder ? 'Nazwa*' : null}
            value={name}
            onChange={(event) => onChange(event, 'name')}
            invalid={hasError(errors, 'name')}
            required={required}
          />
          {hasError(errors, 'name') && <FormFeedback>{getError(errors, 'name')}</FormFeedback>}
        </FormGroup>,
        <FormGroup>
          {!placeholder && <Label for="input-name">Imię</Label>}
          <Input
            type="text"
            name="first_name"
            id="input-first-name"
            placeholder={placeholder ? 'Imię*' : null}
            value={first_name}
            onChange={(event) => onChange(event, 'first_name')}
            invalid={hasError(errors, 'first_name')}
            maxLength={200}
          />
          {hasError(errors, 'first_name') && <FormFeedback>{getError(errors, 'first_name')}</FormFeedback>}
        </FormGroup>,
        <FormGroup>
          {!placeholder && <Label for="input-last-name">Nazwisko</Label>}
          <Input
            type="text"
            name="last_name"
            id="input-last-name"
            placeholder={placeholder ? 'Nazwisko' : null}
            value={last_name}
            onChange={(event) => onChange(event, 'last_name')}
            invalid={hasError(errors, 'last_name')}
            maxLength={200}
          />
          {hasError(errors, 'last_name') && <FormFeedback>{getError(errors, 'last_name')}</FormFeedback>}
        </FormGroup>,
        <FormGroup>
          {!placeholder && <Label for="input-email">Adres email</Label>}
          <Input
            type="email"
            name="email"
            id="input-email"
            placeholder={placeholder ? 'Adres email' : null}
            value={email}
            onChange={(event) => onChange(event, 'email')}
            invalid={hasError(errors, 'email')}
          />
          {hasError(errors, 'email') && <FormFeedback>{getError(errors, 'email')}</FormFeedback>}
        </FormGroup>,
        <FormGroup>
          {!placeholder && <Label for="input-phone">Telefon</Label>}
          <Input
            type="number"
            name="phone"
            id="input-phone"
            placeholder={placeholder ? 'Telefon' : null}
            value={phone}
            onChange={(event) => onChange(event, 'phone')}
            invalid={hasError(errors, 'phone')}
          />
          {hasError(errors, 'phone') && <FormFeedback>{getError(errors, 'phone')}</FormFeedback>}
        </FormGroup>,
        <FormGroup>
          {!placeholder && <Label for="input-city">Miasto</Label>}
          <Input
            type="text"
            name="city"
            id="input-city"
            placeholder={placeholder ? 'Miasto' : null}
            value={city}
            onChange={(event) => onChange(event, 'city')}
            invalid={hasError(errors, 'city')}
          />
          {hasError(errors, 'city') && <FormFeedback>{getError(errors, 'city')}</FormFeedback>}
        </FormGroup>,
        <FormGroup>
          {!placeholder && <Label for="input-voivodeship">Województwo</Label>}
          <Input
            type="text"
            name="voivodeship"
            id="input-voivodeship"
            placeholder={placeholder ? 'Województwo' : null}
            value={voivodeship}
            onChange={(event) => onChange(event, 'voivodeship')}
            invalid={hasError(errors, 'voivodeship')}
          />
          {hasError(errors, 'voivodeship') && <FormFeedback>{getError(errors, 'voivodeship')}</FormFeedback>}
        </FormGroup>,
        <FormGroup>
          {!placeholder && <Label for="input-street">Ulica</Label>}
          <Input
            type="text"
            name="street"
            id="input-street"
            placeholder={placeholder ? 'Ulica' : null}
            value={street}
            onChange={(event) => onChange(event, 'street')}
            invalid={hasError(errors, 'street')}
          />
          {hasError(errors, 'street') && <FormFeedback>{getError(errors, 'street')}</FormFeedback>}
        </FormGroup>,
        <FormGroup>
          {!placeholder && <Label for="input-postal_code">Kod pocztowy</Label>}
          <Input
            type="text"
            name="postal_code"
            id="input-postal_code"
            placeholder={placeholder ? 'Kod pocztowy' : null}
            value={postal_code}
            onChange={(event) => onChange(event, 'postal_code')}
            invalid={hasError(errors, 'postal_code')}
          />
          {hasError(errors, 'postal_code') && <FormFeedback>{getError(errors, 'postal_code')}</FormFeedback>}
        </FormGroup>,
        <FormGroup>
          {!placeholder && <Label for="input-post_office">Miejscowość poczty</Label>}
          <Input
            type="text"
            name="post_office"
            id="input-post_office"
            placeholder={placeholder ? 'Miejscowość poczty' : null}
            value={post_office}
            onChange={(event) => onChange(event, 'post_office')}
            invalid={hasError(errors, 'post_office')}
          />
          {hasError(errors, 'post_office') && <FormFeedback>{getError(errors, 'post_office')}</FormFeedback>}
        </FormGroup>,
        <FormGroup className="mb-0">
          {!placeholder && <Label for="input-business_start_date">Data wpisu do rejestru lub ewidencji</Label>}
          <div id="wrapper-input-business_start_date">
            <Input
              type="text"
              name="business_start_date"
              id="input-business_start_date"
              placeholder={placeholder ? 'Data wpisu do rejestru lub ewidencji' : DATE_FORMAT_SLASH}
              value={business_start_date}
              onChange={(event) => onChange(event, 'business_start_date')}
              invalid={hasError(errors, 'business_start_date')}
            />
          </div>
          {business_start_date && !moment(business_start_date, DATE_FORMAT_SLASH, true).isValid() && (
            <FormFeedback className="d-block">Obsługiwany format: {DATE_FORMAT_SLASH}.</FormFeedback>
          )}
          {hasError(errors, 'business_start_date') && (
            <FormFeedback className="d-block">{getError(errors, 'business_start_date')}</FormFeedback>
          )}
        </FormGroup>,
        <FormGroup>
          {!placeholder && <Label for="input-krs">KRS</Label>}
          <Input
            type="text"
            name="business_krs"
            id="input-krs"
            placeholder={placeholder ? 'KRS' : null}
            value={krs}
            onChange={(event) => onChange(event, 'krs')}
            invalid={hasError(errors, 'krs')}
          />
          {hasError(errors, 'krs') && <FormFeedback className="d-block">{getError(errors, 'krs')}</FormFeedback>}
        </FormGroup>,
        executorFromGus !== null && (
          <FormGroup>
            <Label for="data_correctness_status">
              <span className="h5 pb-1">Zgodność poprawnych danych</span>
            </Label>
            <div className="d-flex flex-wrap align-items-center">
              <CustomInput
                id="data_correctness_status"
                type="checkbox"
                name="data_correctness_status"
                label="Potwierdzam poprawność pobranch danych"
                checked={dataCorrectnessStatus}
                required
                onChange={(event) => setDataCorrectnessStatus(event.target.checked)}
              />
            </div>
          </FormGroup>
        )
      ]}
    </fieldset>
  );
};

export default ExecutorFieldset;
