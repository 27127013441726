import React, { useState } from 'react';
import { RootState } from 'app/reducer';
import Listing from 'modules/Layout/component/Listing';
import { useDispatch, useSelector } from 'react-redux';
import { addToastAction } from 'modules/Layout/action';
import { Message } from 'modules/Shared/type';
import { setProceedingsListParamsAction } from 'modules/Proceedings/action/ProceedingsList';
import Proceedings from 'modules/Proceedings/model/Proceedings';
import ProceedingsTable from 'modules/Proceedings/container/ProceedingsList/Table';
import ProceedingsPerPage from 'modules/Proceedings/container/ProceedingsList/PerPage';
import ProceedingsListingPagination from 'modules/Proceedings/container/ProceedingsList/Pagination';
import ProceedingsFilter from 'modules/Proceedings/container/ProceedingsList/Filter';
import { deleteProceedingsCategoryToastSuccess } from 'modules/Proceedings/toasts';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import deleteRequest from 'modules/Shared/helper/APIRequests/deleteRequest';
import { deleteProceedings } from 'modules/Proceedings/repository';
import GenericModalDelete from 'modules/Layout/component/Modal/GenericDeleteModal';

const useAdminProceedingsList = (proceedingsRoute: string, proceedingsType: number) => {
  const dispatch = useDispatch();
  const { fetching } = useSelector((state: RootState) => state.proceedings.proceedingsList);
  const [displayModal, setDisplayModal] = useState<Proceedings>(null);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<Message>(null);

  const dispatchDeleteProceedingsToast = () => dispatch(addToastAction(deleteProceedingsCategoryToastSuccess()));

  const cancelToken = useCancelToken();
  const deleteProceedingsAction = async (judgment_id: number | string) => {
    setLoading(true);
    const {
      cancelled,
      message: messageRes,
      success
    } = await deleteRequest(deleteProceedings, judgment_id, cancelToken);

    if (cancelled) return;
    if (success) {
      setDisplayModal(null);
      dispatchDeleteProceedingsToast();
      dispatch(setProceedingsListParamsAction({}));
    }
    if (messageRes) setMessage(messageRes);
    setLoading(false);
  };

  const jsxFnc = () => (
    <div className="row users-view">
      <div className="col-12">
        {!!displayModal && (
          <GenericModalDelete
            value={displayModal}
            title="Usuwanie postępowania"
            content={(value) => <div>Czy na pewno chcesz usunąć postępowanie o nazwie - {value?.name}?</div>}
            onDeleteClick={(value) => deleteProceedingsAction(value.id)}
            isOpen={!!displayModal}
            toggle={() => setDisplayModal(null)}
            loading={loading}
            message={message}
            resetMessage={() => setMessage(null)}
          />
        )}
        <Listing
          table={
            <ProceedingsTable
              isAdmin
              onDeleteClick={(proceedings) => setDisplayModal(proceedings)}
              proceedingsType={proceedingsType}
            />
          }
          filter={<ProceedingsFilter proceedingsType={proceedingsType} />}
          pagination={<ProceedingsListingPagination path={proceedingsRoute} />}
          perPage={<ProceedingsPerPage />}
          loading={fetching}
        />
      </div>
    </div>
  );

  return { jsxFnc };
};

export default useAdminProceedingsList;
