import { ValidationErrors } from 'modules/Shared/type';
import React from 'react';
import { FormGroup, CustomInput } from 'reactstrap';
import {
  IOrderFormValues,
  IPenaltiesValue,
  ITerminationValue,
  IComplaintValue,
  IMisrepresentationValue
} from 'modules/ExecutorOrder/component/OrderForm';
import OrderBaseFieldset from 'modules/ExecutorOrder/component/Fieldset/BaseOrder';
import OrderPenaltiesFieldset from 'modules/ExecutorOrder/component/Fieldset/PenaltiesOrder';
import OrderTerminationFieldset from 'modules/ExecutorOrder/component/Fieldset/TerminationOrder';
import OrderComplainFieldset from 'modules/ExecutorOrder/component/Fieldset/ComplainOrder';
import OrderMisrepresentationFieldset from 'modules/ExecutorOrder/component/Fieldset/MisrepresentationOrder';

export interface Props {
  values: IOrderFormValues;
  setValues: React.Dispatch<React.SetStateAction<IOrderFormValues>>;
  penaltiesValue?: IPenaltiesValue;
  setPenaltiesValue?: React.Dispatch<React.SetStateAction<IPenaltiesValue>>;
  terminationValue?: ITerminationValue;
  setTerminationValue?: React.Dispatch<React.SetStateAction<ITerminationValue>>;
  complaintValue?: IComplaintValue;
  setComplaintValue?: React.Dispatch<React.SetStateAction<IComplaintValue>>;
  misrepresentationValue?: IMisrepresentationValue;
  setMisrepresentationValue?: React.Dispatch<React.SetStateAction<IMisrepresentationValue>>;
  isEditForm: boolean;
  errors?: ValidationErrors;
  disabled?: boolean;
  required?: boolean;
}

const OrderFieldset: React.FC<Props> = (props: Props): JSX.Element => {
  const {
    values,
    setValues,
    penaltiesValue,
    setPenaltiesValue,
    terminationValue,
    setTerminationValue,
    complaintValue,
    setComplaintValue,
    misrepresentationValue,
    setMisrepresentationValue,
    ...restProps
  } = props;

  const { penalties_checked, termination_checked, complaint_checked, misrepresentation_checked } = values;

  return (
    <div className="row">
      <div className="col-12 col-lg-6">
        <OrderBaseFieldset values={values} setValues={setValues} {...restProps} required />
      </div>
      <div className="col-12 col-lg-6">
        <FormGroup>
          <CustomInput
            type="switch"
            id="checkox-penalties_checked"
            label="Czy w postępowaniu są informacje o karze umownej?"
            checked={penalties_checked}
            onChange={(event) => setValues({ ...values, penalties_checked: event.target.checked })}
          />
        </FormGroup>
        {penalties_checked && (
          <OrderPenaltiesFieldset
            penaltiesValue={penaltiesValue}
            setPenaltiesValue={setPenaltiesValue}
            {...restProps}
          />
        )}
        <div className={`mb-2${penalties_checked ? ' border' : ''}`} />
        <FormGroup>
          <CustomInput
            type="switch"
            id="checkox-termination_checked"
            label="Czy w postępowaniu są informacje o rozwiązaniu umowy?"
            checked={termination_checked}
            onChange={(event) => setValues({ ...values, termination_checked: event.target.checked })}
          />
        </FormGroup>
        {termination_checked && (
          <OrderTerminationFieldset
            terminationValue={terminationValue}
            setTerminationValue={setTerminationValue}
            {...restProps}
          />
        )}
        <div className={`mb-2${termination_checked ? ' border' : ''}`} />
        <FormGroup>
          <CustomInput
            type="switch"
            id="checkox-complaint_checked"
            label="Czy w postępowaniu są informacje o wytoczeniu powództwa w związku z realizacją umowy?"
            checked={complaint_checked}
            onChange={(event) => setValues({ ...values, complaint_checked: event.target.checked })}
          />
        </FormGroup>
        {complaint_checked && (
          <OrderComplainFieldset complaintValue={complaintValue} setComplaintValue={setComplaintValue} {...restProps} />
        )}
        <div className={`mb-2${complaint_checked ? ' border' : ''}`} />
        <FormGroup>
          <CustomInput
            type="switch"
            id="checkox-misrepresentation_checked"
            label="Czy w postępowaniu są informacje o wprowadzeniu zamawiającego w błąd?"
            checked={misrepresentation_checked}
            onChange={(event) => setValues({ ...values, misrepresentation_checked: event.target.checked })}
          />
        </FormGroup>
        {misrepresentation_checked && (
          <OrderMisrepresentationFieldset
            misrepresentationValue={misrepresentationValue}
            setMisrepresentationValue={setMisrepresentationValue}
            {...restProps}
          />
        )}
      </div>
    </div>
  );
};

export default OrderFieldset;
