import _get from 'lodash/get';
import { SearchParams, SelectOption } from 'modules/Shared/type';
import { FilterInputType } from 'modules/Layout/type';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  fetchDictionariesCountries,
  fetchForeignProceedingsIndustryTypes,
  fetchForeignProceedingsProcedures,
  fetchForeignProceedingsSources
} from '../../../../repository';
import { foreignProceedingStatusOptions } from '../../../../model/ForeignProceedings';
import { RootState } from '../../../../../../app/reducer';

export interface Props {
  values: SearchParams;
}

type SlugType = { slug: string; name: string };
type IndustryType = {
  id: number;
  name: string;
  subtypes?: IndustrySubType[];
};

type IndustrySubType = {
  id: number;
  name: string;
};

type DictionaryCountiesType = {
  [key: string]: string;
};

const useFilterValues = ({ values = {} }: Props) => {
  const { filter } = useSelector((state: RootState) => state.foreignProceedings.foreignProceedingsList);
  const [sourcesList, setSourcesList] = useState<SlugType[]>([]);
  const [proceduresList, setProceduresList] = useState<SlugType[]>([]);
  const [industryTypesList, setIndustryTypesListList] = useState<IndustryType[]>([]);
  const [dictionaryCountiesList, setDictionaryCountiesListList] = useState<DictionaryCountiesType[]>([]);
  const [industrySubTypesList, setIndustrySubTypesListList] = useState<IndustrySubType[]>([]);
  const sourcesOptions: SelectOption[] = sourcesList.map((sourceItem) => {
    return {
      value: sourceItem.slug,
      label: sourceItem.name
    };
  });
  const proceduresOptions: SelectOption[] = proceduresList.map((procedureItem) => {
    return {
      value: procedureItem.slug,
      label: procedureItem.name
    };
  });
  const industryTypesOptions: SelectOption[] = industryTypesList.map((industryTypeItem) => {
    return {
      value: industryTypeItem.id,
      label: industryTypeItem.name
    };
  });
  const industrySubTypesOptions: SelectOption[] = industrySubTypesList.map((industrySubTypeItem) => {
    return {
      value: industrySubTypeItem.id,
      label: industrySubTypeItem.name
    };
  });
  const dictionaryCountiesOptions: SelectOption[] = Object.entries(dictionaryCountiesList).map(([key, value]) => {
    return {
      value: key,
      label: String(value)
    };
  });

  useEffect(() => {
    const getValues = async () => {
      const responseSources = await fetchForeignProceedingsSources();
      const responseProcedures = await fetchForeignProceedingsProcedures();
      const responseIndustryTypes = await fetchForeignProceedingsIndustryTypes();
      const responseDictionaryCounties = await fetchDictionariesCountries();
      const newSources = responseSources.data;
      const newProcedures = responseProcedures.data;
      const newIndustryTypes = responseIndustryTypes.data;
      const newDictionaryCounties = responseDictionaryCounties.data.data;
      setSourcesList(newSources);
      setProceduresList(newProcedures);
      setIndustryTypesListList(newIndustryTypes);
      setDictionaryCountiesListList(newDictionaryCounties);
    };
    getValues().then((r) => r);
  }, []);

  useEffect(() => {
    const selectedIndustryType = industryTypesList.find((type) => type.id === Number(values.industry_type_id));
    if (selectedIndustryType?.subtypes.length > 0) {
      setIndustrySubTypesListList(selectedIndustryType.subtypes);
    } else {
      setIndustrySubTypesListList([]);
      const localStorageParams = localStorage.getItem('localFilterParamsforeign_proceedings');
      if (localStorageParams) {
        const parsedParams = JSON.parse(localStorageParams);

        delete parsedParams.filter.industry_sub_type_id;
        localStorage.setItem('localFilterParamsforeign_proceedings', JSON.stringify(parsedParams));
      }
    }
  }, [industryTypesList, values]);

  const getInputs = (): FilterInputType[] => {
    const deadlineDateFrom = _get(filter, 'deadline_date_from') as string;
    const deadlineDateTo = _get(filter, 'deadline_date_to') as string;
    const publishedDateFrom = _get(filter, 'published_date_from') as string;
    const publishedDateTo = _get(filter, 'published_date_to') as string;

    return [
      {
        type: 'text',
        property: 'name',
        label: 'Nazwa postępowania',
        value: _get(values, 'name')
      },
      {
        type: 'text',
        property: 'ref_nr',
        label: 'Numer referencyjny',
        value: _get(values, 'ref_nr')
      },
      {
        type: 'select',
        property: 'procedure',
        label: 'Procedura',
        acceptValueAsString: true,
        options: [
          {
            value: '',
            label: ''
          },
          ...proceduresOptions
        ],
        value: _get(values, 'procedure')
      },
      {
        type: 'date',
        property: 'deadline_date_from',
        label: 'Termin składania ofert od',
        value: deadlineDateFrom,
        min: String(deadlineDateFrom),
        className: 'col-3 col-lg-3 col-xl-2'
      },
      {
        type: 'date',
        property: 'deadline_date_to',
        label: 'Termin składania ofert do',
        value: deadlineDateTo,
        min: String(deadlineDateTo),
        className: 'col-3 col-lg-3 col-xl-2'
      },
      {
        type: 'date',
        property: 'published_date_from',
        label: 'Data publikacji od',
        value: publishedDateFrom,
        min: String(publishedDateFrom),
        className: 'col-3 col-lg-3 col-xl-2'
      },
      {
        type: 'date',
        property: 'published_date_to',
        label: 'Data publikacji do',
        value: publishedDateTo,
        min: String(publishedDateTo),
        className: 'col-3 col-lg-3 col-xl-2'
      },
      {
        type: 'select',
        property: 'slug_source',
        label: 'Źródło postępowań',
        acceptValueAsString: true,
        options: [
          {
            value: '',
            label: ''
          },
          ...sourcesOptions
        ],
        value: _get(values, 'slug_source')
      },
      {
        type: 'select',
        property: 'status',
        label: 'Status postępowania',
        options: [
          {
            value: '',
            label: ''
          },
          ...foreignProceedingStatusOptions()
        ],
        value: _get(values, 'status')
      },
      {
        type: 'select',
        property: 'industry_type_id',
        label: 'Branże',
        acceptValueAsString: true,
        options: [
          {
            value: '',
            label: ''
          },
          ...industryTypesOptions
        ],
        value: _get(values, 'industry_type_id')
      },
      {
        type: 'select',
        property: 'industry_sub_type_id',
        label: 'Branże podgrupa',
        acceptValueAsString: true,
        disabled: values.industry_type_id !== '' && industrySubTypesOptions.length <= 1,
        options: [
          {
            value: '',
            label: ''
          },
          ...industrySubTypesOptions
        ],
        value: _get(values, 'industry_sub_type_id')
      },
      {
        type: 'searchSelect',
        property: 'country_code',
        label: 'Kraj wykonywania zlecenia',
        acceptValueAsString: true,
        options: [
          {
            value: '',
            label: ''
          },
          ...dictionaryCountiesOptions
        ],
        value: _get(values, 'code')
      },
      {
        type: 'text',
        property: 'ordered_by_info',
        label: 'Zamawiający',
        value: _get(values, 'ordered_by_info')
      }
    ];
  };

  return { inputs: getInputs() };
};

export default useFilterValues;
