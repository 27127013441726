import { RootState } from 'app/reducer';
import ListingFilter from 'modules/Layout/component/Listing/Filter';
import { PartialSearchingProps, SearchParams } from 'modules/Shared/type';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setProceedingsListParamsAction } from 'modules/Proceedings/action/ProceedingsList';
import useFilterValues from './FilterValues/useFilterValues';

export interface Props {
  proceedingsType: number;
  values?: SearchParams;
}

const ProceedingsFilter: React.FC<Props> = ({ proceedingsType, values = {} }) => {
  const dispatch = useDispatch();
  const { filter, pagination } = useSelector((state: RootState) => state.proceedings.proceedingsList);
  const setParams = (payload: PartialSearchingProps) => dispatch(setProceedingsListParamsAction(payload));
  const [filterParams, setFilterParams] = useState<SearchParams>({});

  const { inputs } = useFilterValues({ proceedingsType, values });

  const onSearchButtonClicked = () => setParams({ filter: filterParams });

  useEffect(() => {
    const localStorageParams = localStorage.getItem('localFilterParamsproceedings');

    if (localStorageParams) {
      const parsedParams = JSON.parse(localStorageParams);

      setParams({ ...parsedParams });
    } else {
      setParams({ ...filterParams });
    }
  }, []);

  return (
    <ListingFilter
      inputs={inputs}
      filter={filter}
      filtersName={'proceedings'}
      pagination={pagination}
      setParams={(params) => setFilterParams(params.filter)}
      filterName="Wyszukiwanie"
      searchButtonObj={{ onClick: onSearchButtonClicked }}
    />
  );
};

export default ProceedingsFilter;
