import { suffixLabel } from 'modules/Layout/helper/misc';
import AddFiles from 'modules/Shared/helper/Files/AddFiles';
import { getError, hasError } from 'modules/Shared/helper/validation';
import { ValidationErrors } from 'modules/Shared/type';
import React from 'react';
import { FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import { ITerminationValue } from 'modules/ExecutorOrder/component/OrderForm';
import { DATE_FORMAT_SLASH } from 'modules/Shared/helper/utils';
import moment from 'moment';

export interface Props {
  terminationValue: ITerminationValue;
  setTerminationValue: React.Dispatch<React.SetStateAction<ITerminationValue>>;
  isEditForm: boolean;
  errors?: ValidationErrors;
  disabled?: boolean;
  required?: boolean;
}

const OrderTerminationFieldset: React.FC<Props> = (props: Props): JSX.Element => {
  const { terminationValue, setTerminationValue, errors, disabled, required, isEditForm } = props;

  const { termination_date, displayTerminationWysiwyg } = terminationValue;

  return (
    <fieldset className="m-0" disabled={disabled}>
      <FormGroup>
        {displayTerminationWysiwyg && displayTerminationWysiwyg()}

        {hasError(errors, 'termination_description') && (
          <FormFeedback className="d-block">{getError(errors, 'termination_description')}</FormFeedback>
        )}
      </FormGroup>
      <FormGroup>
        <Label for="termination_date">{suffixLabel('Data rozwiązania umowy', required)}</Label>
        <Input
          type="text"
          name="termination_date"
          id="termination_date"
          placeholder={DATE_FORMAT_SLASH}
          value={termination_date}
          onChange={(event) => setTerminationValue({ ...terminationValue, termination_date: event.target.value })}
          invalid={hasError(errors, 'termination_date')}
        />
        {termination_date && !moment(termination_date, DATE_FORMAT_SLASH, true).isValid() && (
          <FormFeedback className="d-block">Obsługiwany format: {DATE_FORMAT_SLASH}.</FormFeedback>
        )}
        {hasError(errors, 'termination_date') && (
          <FormFeedback className="d-block">{getError(errors, 'termination_date')}</FormFeedback>
        )}
      </FormGroup>
      {!isEditForm && (
        <FormGroup>
          <AddFiles
            values={terminationValue as any}
            setValues={setTerminationValue}
            objKey="termination_files"
            maxSizeInMb={50}
          />
        </FormGroup>
      )}
    </fieldset>
  );
};

export default OrderTerminationFieldset;
