import React, { useState } from 'react';
import ActionDelete from 'modules/Layout/component/Action/Delete';
import FileButton from 'modules/Layout/component/Button/File';
import _uniqueId from 'lodash/uniqueId';
import { FormFeedback } from 'reactstrap';
import './index.scss';

export type IFile = {
  file?: File;
  id?: string;
};

type Props = {
  values: unknown & { [objKey: string]: IFile[] };
  setValues: (value: React.SetStateAction<any>) => void;
  objKey?: string;
  maxSizeInMb?: number;
};

const AddFiles: React.FC<Props> = ({ values, setValues, objKey = 'files', maxSizeInMb }) => {
  const [displayError, setDisplayError] = useState(false);
  const removeFile = (fileId: string) => {
    const files: IFile[] = values[objKey] ?? [];

    setValues({
      ...values,
      [objKey]: files.filter((file) => file?.id !== fileId)
    });
  };

  const addFile = (newFiles: FileList) => {
    const fileArr = [];
    let combinedSize = 0;

    for (let i = 0; i < newFiles.length; i += 1) {
      if (maxSizeInMb) {
        const sizeInMb = newFiles[i].size / (1024 * 1024);

        if (maxSizeInMb < sizeInMb) {
          if (!displayError) setDisplayError(true);
          return;
        }

        fileArr.push({ file: newFiles[i], id: _uniqueId(newFiles[i]?.name ?? '') });
        combinedSize += newFiles[i]?.size;
      }
    }

    if (maxSizeInMb) {
      // eslint-disable-next-line
      const sizeInMb = combinedSize / (1024 * 1024);

      if (maxSizeInMb < sizeInMb) {
        if (!displayError) setDisplayError(true);
        return;
      }

      if (displayError) setDisplayError(false);
    }

    setValues({
      ...values,
      [objKey]: [...values[objKey], ...fileArr]
    });
  };

  return (
    <>
      <FileButton label="DODAJ ZAŁĄCZNIK" onChange={addFile} multiple />
      {displayError && (
        <FormFeedback className="d-block">Rozmiar plików nie może przekroczyć {maxSizeInMb} MB.</FormFeedback>
      )}
      <div className="files-wrapper">
        {values[objKey].map((file) => (
          <div key={file?.id} className="file-entry">
            <ActionDelete
              label={<i className="far fa-trash-alt" />}
              title="Usuń plik"
              onClick={() => removeFile(file?.id)}
            />
            <span>{file?.file?.name}</span>
          </div>
        ))}
      </div>
    </>
  );
};

export default AddFiles;
