import { PaginationMeta, SearchingProps } from 'modules/Shared/type';
import Office from 'modules/Office/model/Office';

export interface OfficeListState extends SearchingProps {
  offices: Office[];
  meta?: PaginationMeta;
  fetching: boolean;
}

export const initOfficeListState = (): OfficeListState => ({
  offices: [],
  pagination: {},
  filter: {},
  sort: {},
  fetching: false
});
