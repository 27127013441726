import { BreadcrumbItem } from 'modules/Layout/type';
import { getPathUrl } from 'modules/Shared/helper/api';
// eslint-disable-next-line import/no-cycle
import { ROUTE_FOREIGN_PROCEEDING, ROUTE_FOREIGN_PROCEEDINGS_OPEN, ROUTE_FOREIGN_PROCEEDINGS_OPEN_FAV } from './routes';

const breadcrumbRouteForeignProceedingsOpen = (): BreadcrumbItem[] => [
  {
    title: 'Wyszukiwarka zagranicznych prowadzonych postępowań - lista',
    link: ROUTE_FOREIGN_PROCEEDINGS_OPEN
  }
];

export const breadcrumbRouteForeignProceedings = (): BreadcrumbItem[] => {
  return breadcrumbRouteForeignProceedingsOpen();
};

export const breadcrumbRouteForeignProceedingsDetails = (name?: string, id?: number): BreadcrumbItem[] => [
  ...breadcrumbRouteForeignProceedings(),
  {
    title: `${name ?? 'Wyszukiwarka zagranicznych postępowań'} - szczegóły`,
    link: getPathUrl(ROUTE_FOREIGN_PROCEEDING, { id })
  }
];

export const breadcrumbRouteForeignProceedingsCreate = (): BreadcrumbItem[] => [
  ...breadcrumbRouteForeignProceedings(),
  {
    title: 'Tworzenie'
  }
];

export const breadcrumbRouteForeignProceedingsOpenFav = (): BreadcrumbItem[] => [
  {
    title: 'Ulubione zagraniczne postępowania - lista',
    link: ROUTE_FOREIGN_PROCEEDINGS_OPEN_FAV
  }
];
