import { suffixLabel } from 'modules/Layout/helper/misc';
import AddFiles from 'modules/Shared/helper/Files/AddFiles';
import { getError, hasError } from 'modules/Shared/helper/validation';
import { ValidationErrors } from 'modules/Shared/type';
import React from 'react';
import { FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import { IComplaintValue } from 'modules/ExecutorOrder/component/OrderForm';
import { DATE_FORMAT_SLASH } from 'modules/Shared/helper/utils';
import moment from 'moment';

export interface Props {
  complaintValue: IComplaintValue;
  setComplaintValue: React.Dispatch<React.SetStateAction<IComplaintValue>>;
  isEditForm: boolean;
  errors?: ValidationErrors;
  disabled?: boolean;
  required?: boolean;
}

const OrderComplainFieldset: React.FC<Props> = (props: Props): JSX.Element => {
  const { complaintValue, setComplaintValue, errors, disabled, required, isEditForm } = props;

  const { complaint_date, complaint_identifier, displayComplaintDescWysiwyg, displayComplaintResWysiwyg } =
    complaintValue;

  return (
    <fieldset className="m-0" disabled={disabled}>
      <FormGroup>
        {displayComplaintDescWysiwyg && displayComplaintDescWysiwyg()}

        {hasError(errors, 'complaint_description') && (
          <FormFeedback className="d-block">{getError(errors, 'complaint_description')}</FormFeedback>
        )}
      </FormGroup>
      <FormGroup>
        <Label for="complaint_date">{suffixLabel('Data złożenia pozwu', required)}</Label>
        <Input
          type="text"
          name="complaint_date"
          id="complaint_date"
          placeholder={DATE_FORMAT_SLASH}
          value={complaint_date}
          onChange={(event) => setComplaintValue({ ...complaintValue, complaint_date: event.target.value })}
          invalid={hasError(errors, 'complaint_date')}
        />
        {complaint_date && !moment(complaint_date, DATE_FORMAT_SLASH, true).isValid() && (
          <FormFeedback className="d-block">Obsługiwany format: {DATE_FORMAT_SLASH}.</FormFeedback>
        )}
        {hasError(errors, 'complaint_date') && (
          <FormFeedback className="d-block">{getError(errors, 'complaint_date')}</FormFeedback>
        )}
      </FormGroup>
      <FormGroup>
        <Label for="input-complaint_identifier">{suffixLabel('Sygnatura sprawy sądowej', required)}</Label>
        <Input
          type="text"
          id="input-complaint_identifier"
          value={complaint_identifier}
          onChange={(event) => setComplaintValue({ ...complaintValue, complaint_identifier: event.target.value })}
          invalid={hasError(errors, 'complaint_identifier')}
          maxLength={255}
          required={required}
        />
        {hasError(errors, 'complaint_identifier') && (
          <FormFeedback>{getError(errors, 'complaint_identifier')}</FormFeedback>
        )}
      </FormGroup>
      <FormGroup>
        {displayComplaintResWysiwyg && displayComplaintResWysiwyg()}

        {hasError(errors, 'complaint_result') && (
          <FormFeedback className="d-block">{getError(errors, 'complaint_result')}</FormFeedback>
        )}
      </FormGroup>
      {/* <FormGroup>
        <Label for="input-complaint_result">{suffixValue('Informacje o wyniku postępowania sądowego', required)}</Label>
        <Input
          type="textarea"
          style={{ height: '150px', resize: 'none' }}
          id="input-complaint_result"
          value={complaint_result}
          onChange={(event) => setComplaintValue({ ...complaintValue, complaint_result: event.target.value })}
          invalid={hasError(errors, 'complaint_result')}
          required={required}
        />
        {hasError(errors, 'complaint_result') && <FormFeedback>{getError(errors, 'complaint_result')}</FormFeedback>}
      </FormGroup> */}
      {!isEditForm && (
        <FormGroup>
          <AddFiles
            values={complaintValue as any}
            setValues={setComplaintValue}
            objKey="complaint_files"
            maxSizeInMb={50}
          />
        </FormGroup>
      )}
    </fieldset>
  );
};

export default OrderComplainFieldset;
