import { RootState } from 'app/reducer';
import { setSearchingParamsAction, SetSearchingParamsAction } from 'modules/Shared/action';
import Validation from 'modules/Shared/exception/Validation';
import { createSearchParams } from 'modules/Shared/helper/params';
import { PaginationMeta, PartialSearchingProps } from 'modules/Shared/type';
import { Action, Dispatch } from 'redux';
import _ from 'lodash';
import { fetchForeignProceedings, manageForeignProceedingsFav } from '../../repository';
import ForeignProceedings from '../../model/ForeignProceedings';

export const SET_FOREIGN_PROCEEDINGS_LIST_PARAMS = 'FOREIGN/PROCEEDINGS/LIST/PARAMS/SET';
export const FOREIGN_PROCEEDINGS_LIST = 'FOREIGN/PROCEEDINGS/LIST';
export const FOREIGN_PROCEEDINGS_CLEAR_LIST = 'FOREIGN/PROCEEDINGS/CLEAR/LIST';

export type ForeignProceedingsListAction =
  | SetForeignProceedingsListParamsAction
  | ForeignProceedingsListFetchedAction
  | ClearForeignProceedingsListAction;

export type SetForeignProceedingsListParamsAction = SetSearchingParamsAction<
  typeof SET_FOREIGN_PROCEEDINGS_LIST_PARAMS
>;

export type ClearForeignProceedingsListAction = {
  type: typeof FOREIGN_PROCEEDINGS_CLEAR_LIST;
};

export type ForeignProceedingsListFetchedPayload = {
  list: ForeignProceedings[];
  meta: PaginationMeta;
};

export interface ForeignProceedingsListFetchedAction extends Action<typeof FOREIGN_PROCEEDINGS_LIST> {
  payload: ForeignProceedingsListFetchedPayload;
}

export const setForeignProceedingsListParamsAction =
  (payload: PartialSearchingProps) => async (dispatch: Dispatch, getState: () => RootState) => {
    const currentState = getState()?.foreignProceedings?.foreignProceedingsList;

    if (
      payload?.filter &&
      !payload?.pagination &&
      typeof currentState?.pagination?.page === 'number' &&
      currentState.pagination.page !== 1 &&
      !_.isEqual(currentState.filter, payload.filter)
    ) {
      currentState.pagination.page = 1;
    }

    if (currentState?.filter?.requires_deposit === '1' && payload?.filter?.requires_deposit !== '1') {
      if (currentState?.filter?.deposit_min) delete payload.filter.deposit_min; // eslint-disable-line no-param-reassign
      if (currentState?.filter?.deposit_max) delete payload.filter.deposit_max; // eslint-disable-line no-param-reassign
    }

    const state = { ...currentState, ...payload };

    dispatch(setSearchingParamsAction(SET_FOREIGN_PROCEEDINGS_LIST_PARAMS, payload));
    try {
      const { pagination, filter, sort } = state;

      const searchFilter = {
        ...filter,
        industry_type_id: filter.industry_sub_type_id ? filter.industry_sub_type_id : filter.industry_type_id
      };

      const searchParams = createSearchParams({ pagination, filter: searchFilter, sort });

      const response = await fetchForeignProceedings(searchParams);

      const { data, meta } = response.data;

      dispatch<ForeignProceedingsListFetchedAction>({
        type: FOREIGN_PROCEEDINGS_LIST,
        payload: { list: data, meta }
      });
    } catch (error) {
      if (error instanceof Validation) {
        dispatch<ForeignProceedingsListFetchedAction>({
          type: FOREIGN_PROCEEDINGS_LIST,
          payload: { list: [], meta: null }
        });
      } else {
        throw error;
      }
    }
  };

export const clearForeignProceedingsList = () => async (dispatch: Dispatch) => {
  dispatch<ClearForeignProceedingsListAction>({
    type: FOREIGN_PROCEEDINGS_CLEAR_LIST
  });
};

export const updateForeignProceedingsFavouriteAction =
  (proceeding_id: number, toastFnc?: () => void) => async (dispatch: Dispatch, getState: () => RootState) => {
    const state = getState()?.foreignProceedings?.foreignProceedingsList;

    try {
      const response = await manageForeignProceedingsFav(proceeding_id);

      if (response.status === 204) {
        const { list, meta } = state;

        const findIndex = list.findIndex((value) => value.id === proceeding_id);
        if (findIndex !== -1) list[findIndex].is_saved = !list[findIndex].is_saved;

        dispatch<ForeignProceedingsListFetchedAction>({
          type: FOREIGN_PROCEEDINGS_LIST,
          payload: { list, meta }
        });
      }

      if (toastFnc) toastFnc();
    } catch (error) {
      if (error instanceof Validation) {
        dispatch<ForeignProceedingsListFetchedAction>({
          type: FOREIGN_PROCEEDINGS_LIST,
          payload: { list: [], meta: null }
        });
      } else {
        throw error;
      }
    }
  };

export const removeForeignProceedingsFavouriteAction =
  (proceeding_id: number, toastFnc?: () => void) => async (dispatch: Dispatch, getState: () => RootState) => {
    const state = getState()?.foreignProceedings?.foreignProceedingsList;

    dispatch(setSearchingParamsAction(SET_FOREIGN_PROCEEDINGS_LIST_PARAMS, {}));

    manageForeignProceedingsFav(proceeding_id)
      .then(async () => {
        const { pagination, filter, sort, meta } = state;

        const searchParams = createSearchParams({ pagination, filter, sort });
        const response = await fetchForeignProceedings(searchParams);

        const { data } = response.data;

        dispatch<ForeignProceedingsListFetchedAction>({
          type: FOREIGN_PROCEEDINGS_LIST,
          payload: { list: data, meta }
        });

        if (toastFnc) toastFnc();
      })
      .catch((error) => {
        if (error instanceof Validation) {
          dispatch<ForeignProceedingsListFetchedAction>({
            type: FOREIGN_PROCEEDINGS_LIST,
            payload: { list: [], meta: null }
          });
        } else {
          throw error;
        }
      });
  };
