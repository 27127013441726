import { RootState } from 'app/reducer';
import ListingFilter from 'modules/Layout/component/Listing/Filter';
import { PartialSearchingProps, SearchParams } from 'modules/Shared/type';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setProceedingsListParamsAction } from 'modules/Proceedings/action/ProceedingsList';
import Proceedings from 'modules/Proceedings/model/Proceedings';
import SaveFilterModal from 'modules/Layout/component/ListFilter/SaveFilterModal';
import useSaveFilter from 'modules/Shared/helper/hooks/useSaveFilter';
import { FiltersEntity, PROCEEDINGS_SLUG_FILTER } from 'modules/Layout/model/Filters';
import { createSearchingProps } from 'modules/Shared/helper/params';
import _ from 'lodash';
import useFilterValues from './FilterValues/useFilterValues';

type IProps = {
  proceedingsType: number;
};

const ProceedingsClientFilter: React.FC<IProps> = ({ proceedingsType }) => {
  const dispatch = useDispatch();
  const { filter, pagination } = useSelector((state: RootState) => state.proceedings.proceedingsList);
  const setParams = (payload: PartialSearchingProps) => dispatch(setProceedingsListParamsAction(payload));
  const onSavedFilterClick = async (saveFilter: FiltersEntity) => {
    setParams({ filter: saveFilter });
  };
  const [filterParams, setFilterParams] = useState<SearchParams>({});

  const {
    saveModal,
    setSaveModal,
    cleanMessage,
    loading,
    message,
    filtersList,
    saveFilter,
    onDeleteFilterClick,
    onFilterSelectedClick: onFilterSelectedClickFn,
    filterList1stFetchFinished
  } = useSaveFilter(filter, setParams, PROCEEDINGS_SLUG_FILTER, ['keywords'], {
    onFilterSelectedClickCustomFnc: onSavedFilterClick
  });

  useEffect(() => {
    if (Object.keys(filter || {}).length && !_.isEqual(filter, filterParams)) {
      setFilterParams(filter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const onSearchButtonClicked = () => setParams({ filter: filterParams });

  const onFilterSelectedClick = (id: number) => {
    setFilterParams(filter);
    setParams({ filter });
    onFilterSelectedClickFn(id);
  };

  const filteredFilterList = filtersList.filter(
    (el) => !el.filters.only_saved && el.filters.proceeding_type === proceedingsType
  );

  const searchProps = createSearchingProps(
    '',
    Proceedings.getFilterableAttributes(),
    Proceedings.getSortableAttributes()
  );

  useEffect(() => {
    if (filterList1stFetchFinished) {
      const findDefault = filteredFilterList.find((el) => el.default);
      if (findDefault) {
        onFilterSelectedClick(findDefault.id);
      } else {
        const localStorageParams = localStorage.getItem('localFilterParamsproceedings');

        if (localStorageParams) {
          const parsedParams = JSON.parse(localStorageParams);

          setParams({ ...parsedParams });
        } else {
          setParams({ ...searchProps, filter: { proceeding_type: proceedingsType } });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterList1stFetchFinished]);

  const { inputs } = useFilterValues({ proceedingsType, values: filterParams });

  let disableBtn = false;

  const { proceeding_type: _x, ...restFilter } = filter;
  if (_.isEmpty(restFilter)) {
    disableBtn = true;
  }

  const setMessage = (value: boolean) => {
    if (message) cleanMessage();
    setSaveModal(value);
  };

  return (
    <div className="position-relative">
      <ListingFilter
        inputs={inputs}
        filter={filterParams}
        filtersName={'proceedings'}
        pagination={pagination}
        setParams={(params) => setFilterParams(params.filter)}
        saveFilterBtn={() => setMessage(true)}
        filterName="Wyszukiwanie"
        disableBtn={disableBtn}
        filterObj={{
          filtersList: filteredFilterList,
          onDeleteFilterClick,
          onFilterSelectedClick: onFilterSelectedClickFn,
          loading
        }}
        searchButtonObj={{ onClick: onSearchButtonClicked, disabled: !Object.keys(filterParams).length }}
      />
      {saveModal && (
        <SaveFilterModal
          onSaveClick={saveFilter}
          isOpen={saveModal}
          toggle={() => setMessage(false)}
          message={message}
          loading={loading}
          displayDefaultCheckbox
          shouldRefetchList
        />
      )}
    </div>
  );
};

export default ProceedingsClientFilter;
