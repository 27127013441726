import _debounce from 'lodash/debounce';
import {
  FilterDatetimeProps,
  FilterInputDateProps,
  FilterInputNumberProps,
  FilterInputTextProps
} from 'modules/Layout/type';
import { isValueDirty } from 'modules/Layout/helper/utils';
import React from 'react';
import { FormGroup, Label } from 'reactstrap';
import Datepicker from 'modules/Layout/component/Datepicker';

export type Props = (FilterInputTextProps | FilterInputNumberProps | FilterInputDateProps | FilterDatetimeProps) & {
  disabled?: boolean;
};

export type State = {
  value: string | number;
};

class FilterData extends React.Component<Props, State> {
  protected debounceOnChange: (value: string) => void;

  constructor(props: Props) {
    super(props);

    this.state = { value: props?.value };

    this.onChange = this.onChange.bind(this);
    this.debounceOnChange = _debounce(props.onChange, 500);
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (isValueDirty(this.props, prevProps, ['value'])) {
      if (isValueDirty(this.props, this.state, ['value'])) {
        const { value } = this.props;

        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ value });
      }
    }
  }

  onChange(value: string): void {
    this.setState({ value }, () => this.debounceOnChange(value));
  }

  render(): JSX.Element {
    const { property, label, className, min, max, disabled } = this.props;
    const { value } = this.state;

    return (
      <FormGroup className={className}>
        <Label for={property}>{label}</Label>
        <div className="d-flex flex-wrap align-items-start">
          <div className="datetime-wrapper d-flex flex-wrap">
            <div className="w-100">
              <Datepicker
                name={property}
                id={property}
                onChange={this.onChange}
                value={value ? String(value) : ''}
                min={min}
                max={max}
                disabled={disabled}
              />
            </div>
          </div>
        </div>
      </FormGroup>
    );
  }
}

export default FilterData;
