import React from 'react';
import { Menu } from 'app/menu';
import { OFFICES_ADD, OFFICES_EDIT, OFFICES_IMPORT, OFFICES_INDEX } from 'modules/Office/permissions';
import { ROUTE_OFFICES, ROUTE_OFFICES_EMAIL, ROUTE_OFFICE_CREATE, ROUTE_OFFICE_IMPORTS } from 'modules/Office/routes';
import OfficesIcon from 'modules/Layout/component/Icon/Offices';

const MenuOfficesShared: Menu[] = [
  {
    key: 'offices-nested',
    type: 'nested',
    title: 'Urzędy',
    icon: <OfficesIcon />,
    navLevel: 'second',
    href: '',
    permissions: [OFFICES_INDEX, OFFICES_ADD],
    isActive: (_match: null) => false,
    children: [
      {
        key: 'offices-index',
        type: 'link',
        title: 'Lista',
        href: ROUTE_OFFICES,
        permissions: [OFFICES_INDEX]
      },
      {
        key: 'offices-create',
        type: 'link',
        title: 'Dodaj',
        href: ROUTE_OFFICE_CREATE,
        permissions: [OFFICES_ADD]
      },
      {
        key: 'offices-import',
        type: 'link',
        title: 'Importuj pliki',
        href: ROUTE_OFFICE_IMPORTS,
        permissions: [OFFICES_IMPORT]
      },
      {
        key: 'offices-email',
        type: 'link',
        title: 'Email',
        href: ROUTE_OFFICES_EMAIL,
        permissions: [OFFICES_INDEX, OFFICES_EDIT]
      }
    ]
  }
];

export default MenuOfficesShared;
