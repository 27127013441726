import React, { useEffect, useState } from 'react';
import { RootState } from 'app/reducer';
import Listing from 'modules/Layout/component/Listing';
import { createSearchingProps } from 'modules/Shared/helper/params';
import OfficeFilter from 'modules/Office/container/OfficeList/Filter';
import OfficePagination from 'modules/Office/container/OfficeList/Pagination';
import OfficePerPage from 'modules/Office/container/OfficeList/PerPage';
import OfficeTable from 'modules/Office/container/OfficeList/Table';
import Office from 'modules/Office/model/Office';
import { ROUTE_OFFICES } from 'modules/Office/routes';
import { useDispatch, useSelector } from 'react-redux';
import { PageProps } from 'modules/Layout/type';
import { addToastAction, managePageAction } from 'modules/Layout/action';
import { breadcrumbRouteOffices } from 'modules/Office/breadcrumbs';
import { Message, PartialSearchingProps } from 'modules/Shared/type';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import { deleteOfficeToastSuccess } from 'modules/Office/toasts';
import { setOfficeListParamsAction } from 'modules/Office/action/list';
import { deleteOffice } from 'modules/Office/repository';
import deleteRequest from 'modules/Shared/helper/APIRequests/deleteRequest';
import GenericModalDelete from 'modules/Layout/component/Modal/GenericDeleteModal';

const Offices = () => {
  const dispatch = useDispatch();
  const { fetching } = useSelector((state: RootState) => state.office.list);
  const [displayModal, setDisplayModal] = useState<Office>(null);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<Message>(null);

  const managePage = (payload: PageProps) => dispatch(managePageAction(payload));
  const setParams = (payload: PartialSearchingProps) => dispatch(setOfficeListParamsAction(payload));

  const searchProps = createSearchingProps('', Office.getFilterableAttributes(), Office.getSortableAttributes());

  useEffect(() => {
    managePage({
      title: 'Urzędy - lista',
      breadcrumb: breadcrumbRouteOffices()
    });

    setParams({ ...searchProps });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dispatchDeleteOfficeToast = () => dispatch(addToastAction(deleteOfficeToastSuccess()));

  const cancelToken = useCancelToken();
  const deleteOfficeAction = async (office_id: number | string) => {
    setLoading(true);
    const { cancelled, success, message: messageRequest } = await deleteRequest(deleteOffice, office_id, cancelToken);

    if (cancelled) return;
    if (success) {
      dispatchDeleteOfficeToast();
      setDisplayModal(null);
      dispatch(setOfficeListParamsAction({}));
    }
    if (messageRequest) setMessage(messageRequest);
    setLoading(false);
  };

  return (
    <div className="row offices-view">
      <div className="col-12">
        {displayModal && (
          <GenericModalDelete
            value={displayModal}
            title="Usuwanie urzędu"
            content={(value) => <div>Czy na pewno chcesz usunąć urząd - {value?.name}?</div>}
            onDeleteClick={(value) => deleteOfficeAction(value.id)}
            isOpen={!!displayModal}
            toggle={() => setDisplayModal(null)}
            loading={loading}
            message={message}
            resetMessage={() => setMessage(null)}
          />
        )}
        <Listing
          table={<OfficeTable onDeleteClick={(office) => setDisplayModal(office)} />}
          filter={<OfficeFilter values={searchProps.filter} />}
          pagination={<OfficePagination path={ROUTE_OFFICES} />}
          perPage={<OfficePerPage />}
          loading={fetching}
        />
      </div>
    </div>
  );
};

export default Offices;
