import CardBox from 'modules/Layout/component/CardBox';
import React from 'react';
import Order, { PenaltiesFileEntity } from 'modules/ExecutorOrder/model/Order';
import ViolationWrapper from 'modules/ExecutorOrder/component/Dataset/Violations';

export interface Props {
  order?: Order;
  orderDownloadFileRequest: (fileId: number | string) => Promise<void>;
  onDeleteFile: (file: PenaltiesFileEntity) => void;
  loadingFile: boolean;
  onAddFilesRequest: (
    section_slug: number | string,
    data: FormData,
    changeLoading: (curr: boolean) => void,
    setProgress: (value: React.SetStateAction<number>) => void
  ) => Promise<void>;
  redirectUrl?: string;
}

const ViolationsDetails: React.FC<Props> = (props) => {
  const { redirectUrl, ...restProps } = props;

  return (
    <CardBox heading="Naruszenia" redirectReturnBtnBottom={redirectUrl}>
      <ViolationWrapper {...restProps} />
    </CardBox>
  );
};

export default ViolationsDetails;
