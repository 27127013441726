import listReducer from 'modules/Executor/reducer/list';
import adminListReducer from 'modules/Executor/reducer/adminList';
import { combineReducers, Reducer } from 'redux';
import { ExecutorState } from 'modules/Executor/state';

const reducer = (): Reducer<ExecutorState> => {
  const reducers = {
    list: listReducer,
    adminList: adminListReducer
  };

  return combineReducers<ExecutorState>(reducers);
};

export default reducer;
