import axios, { AxiosPromise } from 'axios';
import { getAPIUrl } from 'modules/Shared/helper/api';
import { OrderEntity } from 'modules/ExecutorOrder/model/Order';
import { PaginationMeta, SearchParams } from 'modules/Shared/type';

export const API_ORDER_DETAILS_CRUD = '/api/orders/:executor_id/:order_id';
export const API_ORDER_CRUD = '/api/orders/:executor_id';
export const API_ORDER_FILE = '/api/orders/:executor_id/:order_id/files/:file_id';
export const API_ORDER_FILE_ADD = '/api/orders/:executor_id/:order_id/files/:section_slug';

export type FetchOrdersResponse = {
  data: OrderEntity[];
  meta: PaginationMeta;
  links: any;
};

export const fetchOrders = (
  executor_id: number | string,
  params: SearchParams = {}
): AxiosPromise<FetchOrdersResponse> => axios.get(getAPIUrl(API_ORDER_CRUD, { executor_id }, params));

export type FetchOrderResponse = {
  data: OrderEntity;
};

export const fetchOrder = (
  executor_id: string | number,
  order_id: string | number,
  cancelToken?: any
): AxiosPromise<FetchOrderResponse> =>
  axios.get(getAPIUrl(API_ORDER_DETAILS_CRUD, { executor_id, order_id }), { cancelToken });

export const createOrder = (
  executor_id: string | number,
  data: OrderEntity,
  cancelToken?: any
): AxiosPromise<FetchOrderResponse> => axios.post(getAPIUrl(API_ORDER_CRUD, { executor_id }), data, { cancelToken });

export const updateOrder = (
  executor_id: string | number,
  order_id: string | number,
  data: OrderEntity,
  cancelToken?: any
): AxiosPromise<FetchOrderResponse> =>
  axios.put(getAPIUrl(API_ORDER_DETAILS_CRUD, { executor_id, order_id }), data, { cancelToken });

export const deleteOrder = (executor_id: string | number, order_id: string | number, cancelToken?: any): AxiosPromise =>
  axios.delete(getAPIUrl(API_ORDER_DETAILS_CRUD, { executor_id, order_id }), { cancelToken });

export const deleteOrderFile = (
  executor_id: string | number,
  order_id: string | number,
  file_id: string | number,
  cancelToken?: any
): AxiosPromise<FetchOrderResponse> =>
  axios.delete(getAPIUrl(API_ORDER_FILE, { executor_id, order_id, file_id }), { cancelToken });

export const fetchOrderFile = (
  executor_id: number | string,
  order_id: number | string,
  file_id: number | string,
  cancelToken?: any
): AxiosPromise =>
  axios.get(getAPIUrl(API_ORDER_FILE, { executor_id, order_id, file_id }), {
    responseType: 'blob',
    cancelToken
  });

export const addOrderFile = (
  executor_id: number | string,
  order_id: number | string,
  section_slug: string,
  data: FormData,
  onUploadProgress: (progressEvent: any) => void,
  cancelToken?: any
): AxiosPromise<FetchOrderResponse> =>
  axios.post(getAPIUrl(API_ORDER_FILE_ADD, { executor_id, order_id, section_slug }), data, {
    onUploadProgress,
    cancelToken
  });
