import { suffixLabel } from 'modules/Layout/helper/misc';
import AddFiles from 'modules/Shared/helper/Files/AddFiles';
import { getError, hasError } from 'modules/Shared/helper/validation';
import { ValidationErrors } from 'modules/Shared/type';
import React from 'react';
import { FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import { IMisrepresentationValue } from 'modules/ExecutorOrder/component/OrderForm';
import { DATE_FORMAT_SLASH } from 'modules/Shared/helper/utils';
import moment from 'moment';

export interface Props {
  misrepresentationValue: IMisrepresentationValue;
  setMisrepresentationValue: React.Dispatch<React.SetStateAction<IMisrepresentationValue>>;
  isEditForm: boolean;
  errors?: ValidationErrors;
  disabled?: boolean;
  required?: boolean;
}

const OrderMisrepresentationFieldset: React.FC<Props> = (props: Props): JSX.Element => {
  const { misrepresentationValue, setMisrepresentationValue, errors, disabled, required, isEditForm } = props;

  const { exclusion_date, exclusion_identifier, displayMisrepresentationWysiwyg } = misrepresentationValue;

  return (
    <fieldset className="m-0" disabled={disabled}>
      <FormGroup>
        {displayMisrepresentationWysiwyg && displayMisrepresentationWysiwyg()}

        {hasError(errors, 'exclusion_description') && (
          <FormFeedback className="d-block">{getError(errors, 'exclusion_description')}</FormFeedback>
        )}
      </FormGroup>
      <FormGroup>
        <Label for="exclusion_date">{suffixLabel('Data wykluczenia', required)}</Label>
        <Input
          type="text"
          name="exclusion_date"
          id="exclusion_date"
          placeholder={DATE_FORMAT_SLASH}
          value={exclusion_date}
          onChange={(event) =>
            setMisrepresentationValue({ ...misrepresentationValue, exclusion_date: event.target.value })
          }
          invalid={hasError(errors, 'complaint_date')}
        />
        {exclusion_date && !moment(exclusion_date, DATE_FORMAT_SLASH, true).isValid() && (
          <FormFeedback className="d-block">Obsługiwany format: {DATE_FORMAT_SLASH}.</FormFeedback>
        )}
        {hasError(errors, 'exclusion_date') && (
          <FormFeedback className="d-block">{getError(errors, 'exclusion_date')}</FormFeedback>
        )}
      </FormGroup>
      <FormGroup>
        <Label for="input-exclusion_identifier">
          {suffixLabel('Sygnatura wyroku Krajowej Izby Odwoławczej', required)}
        </Label>
        <Input
          type="text"
          id="input-exclusion_identifier"
          value={exclusion_identifier}
          onChange={(event) =>
            setMisrepresentationValue({ ...misrepresentationValue, exclusion_identifier: event.target.value })
          }
          invalid={hasError(errors, 'exclusion_identifier')}
          maxLength={255}
          required={required}
        />
        {hasError(errors, 'exclusion_identifier') && (
          <FormFeedback>{getError(errors, 'exclusion_identifier')}</FormFeedback>
        )}
      </FormGroup>
      {!isEditForm && (
        <FormGroup>
          <AddFiles
            values={misrepresentationValue as any}
            setValues={setMisrepresentationValue}
            objKey="misrepresentation_files"
            maxSizeInMb={50}
          />
        </FormGroup>
      )}
    </fieldset>
  );
};

export default OrderMisrepresentationFieldset;
