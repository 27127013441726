import { BreadcrumbItem } from 'modules/Layout/type';
import { EXECUTORS_INDEX } from 'modules/Executor/permissions';
import {
  ROUTE_EXECUTOR,
  ROUTE_EXECUTOR_ADMINS,
  ROUTE_EXECUTORS,
  ROUTE_EXECUTORS_ADMINS
} from 'modules/Executor/routes';
import { getPathUrl } from 'modules/Shared/helper/api';

export const breadcrumbExecutors = (): BreadcrumbItem => ({
  title: 'Baza wykonawców, w stosunku do których mogą wystąpić podstawy do wykluczenia - wyszukiwanie',
  link: ROUTE_EXECUTORS
});

export const breadcrumbExecutorsAdmin = (): BreadcrumbItem => ({
  title: 'Baza wykonawców, w stosunku do których mogą wystąpić podstawy do wykluczenia - lista',
  link: ROUTE_EXECUTORS_ADMINS,
  permissions: [EXECUTORS_INDEX]
});

export const breadcrumbRouteExecutors = (): BreadcrumbItem[] => [
  {
    title: 'Baza wykonawców, w stosunku do których mogą wystąpić podstawy do wykluczenia - wyszukiwanie'
  }
];

export const breadcrumbRouteExecutor = (id?: string | number, isAdmin?: boolean, name?: string): BreadcrumbItem[] => [
  isAdmin ? breadcrumbExecutorsAdmin() : breadcrumbExecutors(),
  {
    title: `${name} - szczegóły`,
    link: getPathUrl(isAdmin ? ROUTE_EXECUTOR_ADMINS : ROUTE_EXECUTOR, { id })
  }
];

export const breadcrumbRouteExecutorCreate = (isAdmin?: boolean): BreadcrumbItem[] => [
  isAdmin ? breadcrumbExecutorsAdmin() : breadcrumbExecutors(),
  {
    title: 'Tworzenie'
  }
];

export const breadcrumbRouteExecutorUpdate = (id: number, isAdmin?: boolean, name?: string): BreadcrumbItem[] => [
  ...breadcrumbRouteExecutor(id, isAdmin, name),
  {
    title: 'Edycja danych podstawowych'
  }
];

export const breadcrumbRouteExecutorExplanationSection = (): BreadcrumbItem[] => [
  breadcrumbExecutorsAdmin(),
  {
    title: 'Modyfikacja wyjaśnień sekcji raportu'
  }
];
