import React, { useEffect, useState } from 'react';
import { RootState } from 'app/reducer';
import Listing from 'modules/Layout/component/Listing';
import { createSearchingProps } from 'modules/Shared/helper/params';
import { useDispatch, useSelector } from 'react-redux';
import { PageProps } from 'modules/Layout/type';
import { addToastAction, managePageAction } from 'modules/Layout/action';
import { Message, PartialSearchingProps } from 'modules/Shared/type';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import { setClauseListParamsAction } from 'modules/Clause/action/ClauseList';
import Clause, { ClauseEntity } from 'modules/Clause/model/Clause';
import { deleteClause } from 'modules/Clause/repository';
import deleteRequest from 'modules/Shared/helper/APIRequests/deleteRequest';
import GenericModalDelete from 'modules/Layout/component/Modal/GenericDeleteModal';
import { deleteClauseToastSuccess } from 'modules/Clause/toasts';
import ClauseTable from 'modules/Clause/container/AdminClauseList/Table';
import ClausePerPage from 'modules/Clause/container/AdminClauseList/PerPage';
import ClauseListingPagination from 'modules/Clause/container/AdminClauseList/Pagination';
import { ROUTE_CLAUSE_LIST } from 'modules/Clause/routes';
import ClauseFilter from 'modules/Clause/container/AdminClauseList/Filter';
import { breadcrumbRouteClauseList } from 'modules/Clause/breadcrumbs';

const ClauseListView = () => {
  const [displayDeleteModal, setDisplayDeleteModal] = useState<ClauseEntity>(null);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<Message>(null);
  const dispatch = useDispatch();
  const { fetching } = useSelector((state: RootState) => state.clause.clauseList);

  const managePage = (payload: PageProps) => dispatch(managePageAction(payload));
  const setParams = (payload: PartialSearchingProps) => dispatch(setClauseListParamsAction(payload));

  const searchProps = createSearchingProps('', Clause.getFilterableAttributes(), Clause.getSortableAttributes());

  useEffect(() => {
    managePage({
      title: 'Rejestr klauzul niedozwolonych - lista',
      breadcrumb: breadcrumbRouteClauseList()
    });

    setParams({ ...searchProps });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dispatchDeleteClauseToast = () => dispatch(addToastAction(deleteClauseToastSuccess()));

  const cancelToken = useCancelToken();
  const deleteClauseAction = async (judgment_id: number | string) => {
    setLoading(true);
    const { cancelled, message: messageRes, success } = await deleteRequest(deleteClause, judgment_id, cancelToken);

    if (cancelled) return;
    if (success) {
      setDisplayDeleteModal(null);
      dispatchDeleteClauseToast();
      dispatch(setClauseListParamsAction({}));
    }
    if (messageRes) setMessage(messageRes);
    setLoading(false);
  };

  return (
    <div className="row users-view">
      <div className="col-12">
        {!!displayDeleteModal && (
          <GenericModalDelete
            value={displayDeleteModal}
            title="Usuwanie klauzuli"
            content={(value) => <div>Czy na pewno chcesz usunąć klauzulę o nazwie - {value?.name}?</div>}
            onDeleteClick={(value) => deleteClauseAction(value.id)}
            isOpen={!!displayDeleteModal}
            toggle={() => setDisplayDeleteModal(null)}
            loading={loading}
            message={message}
            resetMessage={() => setMessage(null)}
          />
        )}
        <Listing
          table={<ClauseTable onDeleteClick={(value) => setDisplayDeleteModal(value)} />}
          filter={<ClauseFilter values={searchProps.filter} />}
          pagination={<ClauseListingPagination path={ROUTE_CLAUSE_LIST} />}
          perPage={<ClausePerPage />}
          loading={fetching}
        />
      </div>
    </div>
  );
};

export default ClauseListView;
