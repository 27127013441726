import { BreadcrumbItem } from 'modules/Layout/type';
import { getPathUrl } from 'modules/Shared/helper/api';
import { OFFICES_ADD, OFFICES_IMPORT, OFFICES_INDEX } from 'modules/Office/permissions';
import { ROUTE_OFFICE, ROUTE_OFFICES, ROUTE_OFFICES_EMAIL, ROUTE_OFFICE_IMPORTS } from 'modules/Office/routes';

export const breadcrumbOffices = (): BreadcrumbItem => ({
  title: 'Urzędy - lista',
  link: ROUTE_OFFICES,
  permissions: [OFFICES_INDEX]
});

export const breadcrumbRouteOffices = (): BreadcrumbItem[] => [
  {
    title: 'Urzędy - lista'
  }
];

export const breadcrumbRouteOfficeImports = (): BreadcrumbItem[] => [
  {
    title: 'Urzędy – Importuj pliki',
    link: ROUTE_OFFICE_IMPORTS,
    permissions: [OFFICES_IMPORT]
  }
];

export const breadcrumbRouteOfficeImport = (file_name: string): BreadcrumbItem[] => [
  ...breadcrumbRouteOfficeImports(),
  {
    title: `Importowany plik${file_name ? ` - ${file_name}` : ''}`
  }
];

export const breadcrumbRouteOffice = (id: string | number, name?: string): BreadcrumbItem[] => [
  breadcrumbOffices(),
  {
    title: `${name || 'Urzędy'} - szczegóły`,
    link: getPathUrl(ROUTE_OFFICE, { id }),
    permissions: [OFFICES_ADD]
  }
];

export const breadcrumbRouteExecutorRequest = (id: string | number, name?: string): BreadcrumbItem[] => [
  ...breadcrumbRouteOffice(id, name),
  {
    title: 'Zapytania'
  }
];

export const breadcrumbRouteOfficeCreate = (): BreadcrumbItem[] => [
  breadcrumbOffices(),
  {
    title: 'Tworzenie'
  }
];

export const breadcrumbRouteOfficeUpdate = (id: number, name?: string): BreadcrumbItem[] => [
  ...breadcrumbRouteOffice(id, name),
  {
    title: 'Edycja danych podstawowych'
  }
];

export const breadcrumbRouteOfficeEmail = (): BreadcrumbItem[] => [
  breadcrumbOffices(),
  {
    title: 'Komunikacja z urzędami - email',
    link: ROUTE_OFFICES_EMAIL
  }
];

export const breadcrumbRouteOfficeEmailResend = (): BreadcrumbItem[] => [
  ...breadcrumbRouteOfficeEmail(),
  {
    title: 'Wysyłanie email'
  }
];
