import React from 'react';
import InformationList from 'modules/Layout/component/InformationList';
import Order from 'modules/ExecutorOrder/model/Order';
import { formatDate, ifContainsDateFormat } from 'modules/Shared/helper/utils';

export interface Props {
  order?: Order;
}

const PenaltiesProblem: React.FC<Props> = ({ order = {} }) => {
  const fields = [
    {
      label: 'Przyczyny naliczenia kary umownej',
      value: order.penalty_description ?? 'nie dotyczy',
      html: true
    },
    {
      label: 'Data naliczenia kary umownej',
      value: ifContainsDateFormat(order.penalty_date, formatDate) ?? 'nie dotyczy'
    },
    {
      label: 'Wartość wynagrodzenia wykonawcy',
      value: order?.executor_salary ? `${order.executor_salary.toFixed(2).replace('.', ',')} PLN` : 'nie dotyczy'
    },
    {
      label: 'Wysokość kary umownej',
      value: order?.penalty_amount ? `${order.penalty_amount.toFixed(2).replace('.', ',')} PLN` : 'nie dotyczy'
    }
  ];

  return <InformationList fields={fields} />;
};

export default PenaltiesProblem;
