import { getError, hasError } from 'modules/Shared/helper/validation';
import { ValidationErrors } from 'modules/Shared/type';
import React from 'react';
import { FormFeedback, FormGroup } from 'reactstrap';
import {
  COMPLAINT_SLUG,
  MISREPRESENTATION_SLUG,
  PENALTIES_SLUG,
  TERMINATION_SLUG
} from 'modules/ExecutorOrder/model/Order';

export interface Props {
  displayPenaltiesWysiwyg: () => JSX.Element;
  displayTerminationWysiwyg: () => JSX.Element;
  displayMisrepresentationWysiwyg: () => JSX.Element;
  displayComplaintWysiwyg: () => JSX.Element;
  errors?: ValidationErrors;
}

const ExplanationSectionForm: React.FC<Props> = (props: Props): JSX.Element => {
  const {
    displayPenaltiesWysiwyg,
    displayTerminationWysiwyg,
    displayMisrepresentationWysiwyg,
    displayComplaintWysiwyg,
    errors
  } = props;

  return (
    <fieldset className="m-0">
      <FormGroup className="form-group-large-margins">
        {displayPenaltiesWysiwyg && displayPenaltiesWysiwyg()}

        {hasError(errors, PENALTIES_SLUG) && (
          <FormFeedback className="d-block">{getError(errors, PENALTIES_SLUG)}</FormFeedback>
        )}
      </FormGroup>
      <FormGroup className="form-group-large-margins">
        {displayTerminationWysiwyg && displayTerminationWysiwyg()}

        {hasError(errors, TERMINATION_SLUG) && (
          <FormFeedback className="d-block">{getError(errors, TERMINATION_SLUG)}</FormFeedback>
        )}
      </FormGroup>
      <FormGroup className="form-group-large-margins">
        {displayComplaintWysiwyg && displayComplaintWysiwyg()}

        {hasError(errors, COMPLAINT_SLUG) && (
          <FormFeedback className="d-block">{getError(errors, COMPLAINT_SLUG)}</FormFeedback>
        )}
      </FormGroup>
      <FormGroup className="form-group-large-margins">
        {displayMisrepresentationWysiwyg && displayMisrepresentationWysiwyg()}

        {hasError(errors, MISREPRESENTATION_SLUG) && (
          <FormFeedback className="d-block">{getError(errors, MISREPRESENTATION_SLUG)}</FormFeedback>
        )}
      </FormGroup>
    </fieldset>
  );
};

export default ExplanationSectionForm;
