import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { addToastAction, managePageAction } from 'modules/Layout/action';
import { useDispatch, useSelector } from 'react-redux';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import { Message } from 'modules/Shared/type';
import Loader from 'modules/Layout/component/Loader';
import Alert from 'modules/Layout/component/Alert';
import fetchRequest from 'modules/Shared/helper/APIRequests/fetchRequest';
import deleteRequest from 'modules/Shared/helper/APIRequests/deleteRequest';
import GenericModalDelete from 'modules/Layout/component/Modal/GenericDeleteModal';
import { push } from 'connected-react-router';
import { RootState } from 'app/reducer';
import { ROLE_ADMIN } from 'modules/User/model/UserRole';
import { analytics } from 'firebase';
import ForeignProceedings, { createForeignProceedings } from '../../model/ForeignProceedings';
import { deleteForeignProceedingsCategoryToastSuccess } from '../../toasts';
import { deleteForeignProceedings, fetchForeignProceedingsDetails } from '../../repository';
import { breadcrumbRouteForeignProceedingsDetails } from '../../breadcrumbs';
import { ROUTE_FOREIGN_PROCEEDINGS_OPEN } from '../../routes';
import ForeignProceedingsBasicDetails from '../../component/Details';

const ForeignProceedingsDetails = () => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const [foreignProceedings, setForeignProceedings] = useState<ForeignProceedings>(null);
  const [message, setMessage] = useState<Message>(null);
  const [fetching, setFetching] = useState(false);
  const [displayModal, setDisplayModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatchDeleteForeignProceedingsToast = () =>
    dispatch(addToastAction(deleteForeignProceedingsCategoryToastSuccess()));

  const cancelToken = useCancelToken();
  useEffect(() => {
    (async () => {
      setFetching(true);
      const {
        data,
        cancelled,
        message: messageResponse
      } = await fetchRequest(fetchForeignProceedingsDetails, id, cancelToken);

      if (cancelled) return;
      if (data) setForeignProceedings(createForeignProceedings(data.data));
      if (messageResponse) setMessage(messageResponse);
      setFetching(false);
    })();

    analytics.logEvent('foreign_proceedings_activation');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (foreignProceedings) {
      const urlParams = new URLSearchParams(window.location.search);
      const tab = urlParams.get('list');

      let breadcrumb = breadcrumbRouteForeignProceedingsDetails(foreignProceedings.name, foreignProceedings.id);

      if (tab === 'favourite')
        breadcrumb = breadcrumbRouteForeignProceedingsDetails(foreignProceedings.name, foreignProceedings.id);

      dispatch(
        managePageAction({
          title: 'Szczegóły postępowania',
          breadcrumb
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [foreignProceedings]);

  const deleteForeignProceedingsAction = async (foreignProceedings_id: number | string) => {
    setLoading(true);
    const {
      cancelled,
      message: messageRes,
      success
    } = await deleteRequest(deleteForeignProceedings, foreignProceedings_id, cancelToken);

    if (cancelled) return;
    if (success) {
      dispatchDeleteForeignProceedingsToast();
      dispatch(push(ROUTE_FOREIGN_PROCEEDINGS_OPEN));
    } else {
      if (messageRes) setMessage(messageRes);
      setLoading(false);
    }
  };

  const { role } = useSelector((state: RootState) => state.auth.user);
  const isAdmin = role?.slug === ROLE_ADMIN;

  if (fetching) return <Loader />;
  if (!fetching && !foreignProceedings && message)
    return (
      <div className="user-create-view row justify-content-center">
        <div className="col-lg-7 col-xl-5">
          <div className="card-box position-relative">{message && <Alert message={message} />}</div>
        </div>
      </div>
    );
  if (!fetching && foreignProceedings) {
    return (
      <div className="position-relative">
        {displayModal && (
          <GenericModalDelete
            value={foreignProceedings}
            title="Usunięcie klauzuli"
            content={(value) => <div>Czy na pewno chcesz usunąć postępowanie o nazwie - {value?.name}?</div>}
            onDeleteClick={(modalProceedings) => deleteForeignProceedingsAction(modalProceedings.id)}
            isOpen={displayModal}
            toggle={() => setDisplayModal(null)}
            loading={loading}
            message={message}
            resetMessage={() => setMessage(null)}
          />
        )}
        <ForeignProceedingsBasicDetails
          foreignProceedings={foreignProceedings}
          setDisplayDeleteModal={() => setDisplayModal(true)}
          isAdmin={isAdmin}
        />
      </div>
    );
  }

  return null;
};

export default ForeignProceedingsDetails;
