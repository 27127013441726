import React, { useEffect, useState } from 'react';
import { Message } from 'modules/Shared/type';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import fetchRequest from 'modules/Shared/helper/APIRequests/fetchRequest';
import { useParams } from 'react-router-dom';
import GenericModalDelete from 'modules/Layout/component/Modal/GenericDeleteModal';
import { ProceedingsCPVValues } from 'modules/Proceedings/type';
import useCPVCreateModal from 'modules/ForeignProceedings/component/Modal/CPV/useCPVCreateModal';
import useCPVUpdateModal from 'modules/ForeignProceedings/component/Modal/CPV/useCPVUpdateModal';
import useCPVDeleteModal from 'modules/ForeignProceedings/component/Modal/CPV/useCPVDeleteModal';
import ManageCPVModal from 'modules/ForeignProceedings/component/Modal/CPV';
import ForeignProceedingsCPVDetailsDisplay from 'modules/ForeignProceedings/component/Details/CPV/Display';
import { fetchForeignProceedingsCPV } from '../../../repository';
import { ForeignProceedingsCPVValues } from '../../../type';

type Props = {
  isAdmin?: boolean;
};

const ForeignProceedingsCPV: React.FC<Props> = ({ isAdmin }): JSX.Element => {
  const [CPVList, setCPVList] = useState<ProceedingsCPVValues[]>([]);
  const [message, setMessage] = useState<Message>(null);
  const [fetching, setFetching] = useState(false);
  const { id: foreignProceeding_id } = useParams<{ id: string }>();

  const cancelToken = useCancelToken();
  const getForeignProceedingsCPV = async () => {
    setFetching(true);
    const {
      data,
      cancelled,
      message: messageResponse
    } = await fetchRequest(fetchForeignProceedingsCPV, foreignProceeding_id, cancelToken);

    if (cancelled) return;
    if (data) {
      const { data: listData } = data;
      setCPVList(listData);
    }
    if (messageResponse) setMessage(messageResponse);
    setFetching(false);
  };

  useEffect(() => {
    getForeignProceedingsCPV();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onModalEditActionSuccess = (editData?: ProceedingsCPVValues) => {
    const findIndex = CPVList.findIndex((value) => value.id === editData.id);
    setCPVList([...CPVList.slice(0, findIndex), editData, ...CPVList.slice(findIndex + 1)]);
  };

  const {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    addCPVAction,
    loading: addLoading,
    displayAddModal,
    setDisplayAddModal,
    message: addMessage,
    errors: addErrors,
    clearErrors: addCleanErrors
  } = useCPVCreateModal(foreignProceeding_id, getForeignProceedingsCPV);

  const {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    updateCPVAction,
    displayEditModal,
    setDisplayEditModal,
    loading: updateLoading,
    message: updateMessage,
    errors: updateErrors,
    clearErrors: updateCleanErrors
  } = useCPVUpdateModal(foreignProceeding_id, onModalEditActionSuccess);

  const {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    deleteCPVAction,
    displayDeleteModal,
    setDisplayDeleteModal,
    loading: deleteLoading,
    message: deleteMessage,
    clearErrors: deleteCleanErrors
  } = useCPVDeleteModal(foreignProceeding_id, getForeignProceedingsCPV);

  const toggleCreateModal = () => {
    if (!addLoading) setDisplayAddModal(false);
  };

  const toggleEditModal = () => {
    if (!updateLoading) setDisplayEditModal(null);
  };

  const toggleDeleteModal = () => {
    if (!deleteLoading) setDisplayDeleteModal(null);
  };

  const onDeleteClick = (SAOSKeyword: ForeignProceedingsCPVValues) => setDisplayDeleteModal(SAOSKeyword);
  const onEditClick = (SAOSKeyword: ForeignProceedingsCPVValues) => setDisplayEditModal(SAOSKeyword);

  return (
    <>
      {displayAddModal && (
        <ManageCPVModal
          title="Dodaj kod CPV"
          isOpen={displayAddModal}
          toggle={toggleCreateModal}
          loading={addLoading}
          message={addMessage}
          errors={addErrors}
          clearErrorsOnUnmount={addCleanErrors}
          onSubmit={addCPVAction}
        />
      )}
      {displayEditModal && (
        <ManageCPVModal
          element={displayEditModal}
          title="Edytuj kod CPV"
          isOpen={!!displayEditModal}
          toggle={toggleEditModal}
          loading={updateLoading}
          message={updateMessage}
          errors={updateErrors}
          clearErrorsOnUnmount={updateCleanErrors}
          onSubmit={updateCPVAction}
        />
      )}
      {displayDeleteModal && (
        <GenericModalDelete
          value={displayDeleteModal}
          title="Usuń kod CPV"
          // eslint-disable-next-line react/no-unescaped-entities
          content={(value) => <div>Czy na pewno chcesz usunąć kod "{value?.code}"?</div>}
          onDeleteClick={(value) => deleteCPVAction(value.id)}
          isOpen={!!displayDeleteModal}
          toggle={toggleDeleteModal}
          loading={deleteLoading}
          message={deleteMessage}
          resetMessage={deleteCleanErrors}
        />
      )}
      <ForeignProceedingsCPVDetailsDisplay
        CPVList={CPVList}
        fetching={fetching}
        message={message}
        setDisplayAddModal={setDisplayAddModal}
        onDeleteClick={onDeleteClick}
        onEditClick={onEditClick}
        isAdmin={isAdmin}
      />
    </>
  );
};

export default ForeignProceedingsCPV;
