import { Routes } from 'app/routes';
import Authorize from 'modules/Auth/container/Authorize';
import React from 'react';
import { Route } from 'react-router-dom';
import { ForeignProceedingsOpenListView, ForeignProceedingsPlanningListView } from './view';
import ModuleGuard from '../Auth/container/Guard/ModuleGuard';
import { MODULE_FOREIGN_PROCEEDINGS } from '../Module/model/Module';
import ForeignProceedingsDetails from './view/Details';
import ForeignProceedingsFavouritesOpenListView from './view/Favourites';

export const ROUTE_FOREIGN_PROCEEDING = '/foreign-proceedings/:id(\\d+)';
export const ROUTE_FOREIGN_PROCEEDINGS_PLANNING = '/foreign-proceedings/planning';
export const ROUTE_FOREIGN_PROCEEDINGS_OPEN = '/foreign-proceedings/open';
export const ROUTE_FOREIGN_PROCEEDINGS_PLANNING_FAV = '/foreign-proceedings/fav/planning';
export const ROUTE_FOREIGN_PROCEEDINGS_OPEN_FAV = '/foreign-proceedings/fav/open';
export const ROUTE_FOREIGN_PROCEEDINGS_CREATE = '/foreign-proceedings/create';
export const ROUTE_FOREIGN_PROCEEDINGS_UPDATE = '/foreign-proceedings/update/:id(\\d+)';
export const ROUTE_FOREIGN_PROCEEDINGS_PUBLIC_PLANNING = '/foreign-proceedings/public/planning';
export const ROUTE_FOREIGN_PROCEEDINGS_PUBLIC_OPEN = '/foreign-proceedings/public/open';
export const ROUTE_FOREIGN_PROCEEDING_PUBLIC = '/foreign-proceedings/public/:id(\\d+)';

export const createForeignProceedingsRoutes = (): Routes => ({
  wrapped: [
    <Authorize key="foreign-proceedings-planning-list">
      <Route path={ROUTE_FOREIGN_PROCEEDINGS_PLANNING} exact component={ForeignProceedingsPlanningListView} />
    </Authorize>,
    <Authorize key="foreign-proceedings-open-list-index">
      <Route
        path={ROUTE_FOREIGN_PROCEEDINGS_OPEN}
        exact
        render={() => (
          <ModuleGuard moduleSlug={MODULE_FOREIGN_PROCEEDINGS}>
            <ForeignProceedingsOpenListView />
          </ModuleGuard>
        )}
      />
    </Authorize>,
    <Authorize key="foreign-proceedings-open-favourite">
      <Route
        path={ROUTE_FOREIGN_PROCEEDINGS_OPEN_FAV}
        exact
        render={() => (
          <ModuleGuard moduleSlug={MODULE_FOREIGN_PROCEEDINGS}>
            <ForeignProceedingsFavouritesOpenListView />
          </ModuleGuard>
        )}
      />
    </Authorize>,
    <Authorize key="foreign-proceedings-details">
      <Route
        path={ROUTE_FOREIGN_PROCEEDING}
        exact
        render={() => (
          <ModuleGuard moduleSlug={MODULE_FOREIGN_PROCEEDINGS}>
            <ForeignProceedingsDetails />
          </ModuleGuard>
        )}
      />
    </Authorize>
  ]
});
