import Table, { TableCol } from 'modules/Layout/component/Table';
import React from 'react';
import { PenaltiesFileEntity } from 'modules/ExecutorOrder/model/Order';
import { ORDERS_DELETE } from 'modules/ExecutorOrder/permissions';
import Authorize from 'modules/Auth/container/Authorize';
import ActionUpdate from 'modules/Layout/component/Action/Update';
import ActionDelete from 'modules/Layout/component/Action/Delete';
import DownloadIcon from 'modules/Layout/component/Icon/Download';

type Props = {
  orderFiles: PenaltiesFileEntity[];
  orderDownloadFileRequest: (fileId: number | string) => Promise<void>;
  onDeleteFile?: (file: PenaltiesFileEntity) => void;
};

const ViolationFilesTable: React.FC<Props> = ({ orderFiles, orderDownloadFileRequest, onDeleteFile }) => {
  const cols: TableCol<PenaltiesFileEntity>[] = [
    {
      property: 'name',
      label: 'Nazwa'
    },
    {
      property: 'actions',
      label: 'Akcje',
      value: function Actions(row) {
        return (
          <div className="actions-wrapper">
            <Authorize>
              <ActionUpdate
                className="mx-1"
                title="Pobierz plik"
                label={<DownloadIcon height="20px" />}
                onClick={() => orderDownloadFileRequest(row.id)}
              />
            </Authorize>
            {onDeleteFile && (
              <Authorize permissions={[ORDERS_DELETE]}>
                <ActionDelete className="mx-1" title="Usuń plik" onClick={() => onDeleteFile(row)} />
              </Authorize>
            )}
          </div>
        );
      }
    }
  ];

  return <Table cols={cols} rows={orderFiles} />;
};

export default ViolationFilesTable;
