import React from 'react';
import { Menu } from 'app/menu';
import SearchIcon from 'modules/Layout/component/Icon/Search';
import { ROUTE_FOREIGN_PROCEEDINGS_OPEN, ROUTE_FOREIGN_PROCEEDINGS_OPEN_FAV } from './routes';
import { hasModule, MODULE_FOREIGN_PROCEEDINGS } from '../Module/model/Module';
import { SUBSCRIPTIONS_MODULE_ACCESS } from '../Auth/container/Guard/permissions';

const ForeignProceedingsOfficesShared: Menu[] = [
  {
    key: 'foreign-proceedings-client-menu',
    type: 'nested',
    navLevel: 'second',
    href: '#',
    isActive: (_match: null) => false,
    title: 'Wyszukiwarka zagranicznych postępowań',
    hasAccess: (user) =>
      hasModule(MODULE_FOREIGN_PROCEEDINGS, user) || user.hasPermissions([SUBSCRIPTIONS_MODULE_ACCESS]),
    icon: <SearchIcon />,
    children: [
      {
        key: 'foreign-proceedings-client-open-index',
        type: 'link',
        title: 'Prowadzone postępowania',
        href: ROUTE_FOREIGN_PROCEEDINGS_OPEN
      },
      {
        key: 'foreign-proceedings-open-favourite',
        type: 'link',
        title: 'Ulubione postępowania',
        href: ROUTE_FOREIGN_PROCEEDINGS_OPEN_FAV
      }
    ]
  }
];

export default ForeignProceedingsOfficesShared;
