import React from 'react';
import { ISubscriptionObj } from 'modules/Subscriptions/component/Subscriptions';
import DisplayPopovers from 'modules/Layout/component/Popover/DisplayWrapper';
import CardBox from 'modules/Layout/component/CardBox';

type IProps = {
  subscriptionOptions: ISubscriptionObj[];
  subscriptions_id: number[];
  onOptionSelected: (id: number) => void;
};

const SubscriptionDisplayButtons: React.FC<IProps> = ({ subscriptionOptions, subscriptions_id, onOptionSelected }) => {
  const optionClassName = (active?: boolean) =>
    `subscription-btn subscription-item-btn${
      active ? ' subscription-item-btn-active' : ' subscription-item-btn-inactive'
    }`;

  return (
    <div className="subscriptions-wrapper">
      {subscriptionOptions?.map((subscribtion, index) => {
        return (
          <CardBox key={subscribtion.title} childrenPaddingTop="0">
            <div className="card-box-heading">{subscribtion.title}</div>
            <div className="sub-title">
              <div className="d-inline mr-1 font-weight-bold">Pełny dostęp na wskazany okres</div>
              <DisplayPopovers
                popoverId={`PopoverSub${index}`}
                popoverContent={subscribtion.subscription_description}
                className="tooltip-light subscriptions-tooltip-description"
              />
            </div>
            <div className="subscription-description">{subscribtion.description}</div>
            <div className="line-break" />
            <div className="options">
              {subscribtion.unlimitedOptions.map(({ option, id, price, tax, days }) => {
                return (
                  <button
                    key={id}
                    type="button"
                    className={optionClassName(subscriptions_id.includes(id) ?? false)}
                    onClick={() => onOptionSelected(id)}
                  >
                    <div className="option-title">{option}</div>
                    <div className="option-description">
                      <div>
                        <span className="font-weight-bold">{price.toFixed(2).replace('.', ',')}</span> PLN
                      </div>
                      <div className="vat">w tym {tax}% VAT</div>
                      <div className="per-day">1 dzień / {(price / days).toFixed(2).replace('.', ',')} PLN</div>
                    </div>
                  </button>
                );
              })}
            </div>
            {!!subscribtion.limitedOptions.length && (
              <>
                <div className="sub-title second">
                  <div className="d-inline mr-1 font-weight-bold">
                    Dostęp do pojedynczych wykonawców, w stosunku do których mogą wystąpić podstawy do wykluczenia
                  </div>
                  <DisplayPopovers
                    className="tooltip-light subscriptions-tooltip-description"
                    popoverId={`PopoverCredit${index}`}
                    popoverContent={subscribtion.credits_description}
                  />
                </div>
                <div className="line-break" />
                <div className="options">
                  {subscribtion.limitedOptions.map(({ option, id, price, tax }) => {
                    return (
                      <button
                        key={id}
                        type="button"
                        className={optionClassName(subscriptions_id.includes(id) ?? false)}
                        onClick={() => onOptionSelected(id)}
                      >
                        <div className="option-title">{option}</div>
                        <div className="option-description">
                          <div>
                            <span className="font-weight-bold">{price.toFixed(2).replace('.', ',')}</span> PLN
                          </div>
                          <div className="vat">w tym {tax}% VAT</div>
                        </div>
                      </button>
                    );
                  })}
                </div>
              </>
            )}
          </CardBox>
        );
      })}
    </div>
  );
};

export default SubscriptionDisplayButtons;
