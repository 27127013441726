import React, { useEffect } from 'react';
import { RootState } from 'app/reducer';
import Listing from 'modules/Layout/component/Listing';
import { useDispatch, useSelector } from 'react-redux';
import { PageProps } from 'modules/Layout/type';
import { managePageAction } from 'modules/Layout/action';
import { breadcrumbRouteJudgementClientList } from 'modules/JudgementClient/breadcrumbs';
import JudgmentClientTable from 'modules/JudgementClient/container/JudgementClientList/Table';
import JudgementClientFilter from 'modules/JudgementClient/container/JudgementClientList/Filter';
import JudgementClientListingPagination from 'modules/JudgementClient/container/JudgementClientList/Pagination';
import JudgementClientPerPage from 'modules/JudgementClient/container/JudgementClientList/PerPage';
import { ROUTE_JUDGEMENT_CLIENT_LIST } from 'modules/JudgementClient/routes';

const JudgementClientListView = () => {
  const dispatch = useDispatch();
  const { fetching } = useSelector((state: RootState) => state.judgmentClient.judgementClientList);

  const managePage = (payload: PageProps) => dispatch(managePageAction(payload));
  // const setParams = (payload: PartialSearchingProps) => dispatch(setJudgementClientListParamsAction(payload));

  // const searchProps = createSearchingProps('', Judgement.getFilterableAttributes(), Judgement.getSortableAttributes());

  useEffect(() => {
    managePage({
      title: 'Orzeczenia sądowe - wyszukiwanie',
      breadcrumb: breadcrumbRouteJudgementClientList()
    });

    // setParams({ ...searchProps });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="row users-view">
      <div className="col-12">
        <Listing
          table={<JudgmentClientTable />}
          filter={<JudgementClientFilter />}
          pagination={<JudgementClientListingPagination path={ROUTE_JUDGEMENT_CLIENT_LIST} />}
          perPage={<JudgementClientPerPage />}
          loading={fetching}
        />
      </div>
    </div>
  );
};

export default JudgementClientListView;
