import { PermissionSlug } from 'modules/Auth/type';
import { ColorVariants, SelectOption } from 'modules/Shared/type';
import { ModuleEntity } from 'modules/Module/model/Module';
import { OfficeEntity } from 'modules/Office/model/Office';
import { UserEntity } from 'modules/User/model/User';
import { UserCreditsValues } from 'modules/User/type';
import { ExecutorClientList } from 'modules/Executor/model/ExecutorClient';
import { JudgementEntity } from 'modules/Judgement/model/Judgement';
import { ClauseEntity } from 'modules/Clause/model/Clause';
import { ProceedingsEntity } from 'modules/Proceedings/model/Proceedings';

export const TOAST_CLOSE_IN = 15000;
export const SCREEN_MOBILE_WIDTH = 767.98;
export const SCREEN_MEDIUM_WIDTH = 1200;

export enum MODULES_TYPES {
  EXECUTOR = 'executor',
  AGREEMENTS = 'agreements',
  CLAUSE = 'clause',
  ORDERS = 'orders'
}

export const MODULES_OPTIONS = [
  MODULES_TYPES.EXECUTOR,
  MODULES_TYPES.AGREEMENTS,
  MODULES_TYPES.CLAUSE,
  MODULES_TYPES.ORDERS
];

export type PageType = 'loading' | '403' | '404' | '500';

export interface BreadcrumbItem {
  title: string;
  link?: string;
  permissions?: PermissionSlug[];
}

export type PageProps = {
  title: string;
  type?: PageType;
  breadcrumb?: BreadcrumbItem[];
};

export type PartialPageProps = Partial<PageProps>;

export interface Toast {
  id: string;
  header: string;
  body: string;
  color?: ColorVariants;
  closeIn?: number;
}

export interface FilterInputProps<T extends string> {
  type: T;
  property?: string;
  value?: string | number;
  label: string;
  className?: string;
  min?: string;
  max?: string;
  onChange?: (value: string) => void;
  parseTo?: 'string' | 'number';
}

export type FilterInputTextProps = FilterInputProps<'text'>;

export type FilterInputNumberProps = FilterInputProps<'number'>;

export interface FilterInputRadioProps extends FilterInputProps<'radio'> {
  options: SelectOption[];
}

export type FilterInputDateProps = FilterInputProps<'date'>;

export type FilterDatetimeProps = FilterInputProps<'datetime'>;

export interface FilterSelectProps extends FilterInputProps<'select'> {
  options: SelectOption[];
  acceptValueAsString?: boolean;
}

export interface FilterSearchSelectProps extends FilterInputProps<'searchSelect'> {
  options: SelectOption[];
  acceptValueAsString?: boolean;
}

export type FilterCheckboxProps = FilterInputProps<'checkbox'>;

export interface FilterAccordionProps extends FilterInputProps<'accordion'> {
  inputs: any;
}

export type FilterInputType = (
  | FilterInputTextProps
  | FilterInputNumberProps
  | FilterInputRadioProps
  | FilterSelectProps
  | FilterSearchSelectProps
  | FilterInputDateProps
  | FilterDatetimeProps
  | FilterCheckboxProps
  | FilterAccordionProps
  | { type: 'specialCaseJsxElement'; value: any; property?: ''; label?: ''; className?: '' }
) & { beforeLineBreak?: boolean; before_jsx_element?: JSX.Element; disabled?: boolean };

export interface PasswordValues {
  password?: string;
  password_confirmation?: string;
}

export interface DashboardClientExecutor extends ExecutorClientList {
  visited_at: string;
  executor_updated_at: string;
}

export interface DashboardClientClause extends ClauseEntity {
  visited_at: string;
}

export interface DashboardClientJudgement extends JudgementEntity {
  visited_at: string;
}

export interface DashboardClientProceedings extends ProceedingsEntity {
  visited_at: string;
}

export interface DashboardUserValues {
  modules?: ModuleEntity[];
  credits?: UserCreditsValues[];
  executors?: DashboardClientExecutor[];
  judgments?: DashboardClientJudgement[];
  clauses?: DashboardClientClause[];
  proceedings?: DashboardClientProceedings[];
}

export interface DashboardAdminOffice extends OfficeEntity {
  sent_at: string;
}

export interface DashboardAdminExecutor {
  created_at: string;
  id: number;
  nip: string;
  usages: number;
}

export interface DashboardAdminValues {
  users?: UserEntity[];
  executors?: DashboardAdminExecutor[];
  offices?: DashboardAdminOffice[];
  judgments?: JudgementEntity[];
}
