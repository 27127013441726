import React from 'react';
import InformationList from 'modules/Layout/component/InformationList';
import Office from 'modules/Office/model/Office';
import { formatDatetimeSeconds, ifContainsDateFormat } from 'modules/Shared/helper/utils';
// import { ROLE_ADMIN } from 'modules/User/model/UserRole';

export interface Props {
  office: Office;
}

const BasicOfficeDataset: React.FC<Props> = ({ office }) => {
  const fields = [
    {
      label: 'ID',
      value: office?.id
    },
    {
      label: 'Nazwa',
      value: office?.name
    },
    {
      label: 'Email',
      value: office?.email
    },
    {
      label: 'Telefon',
      value: office?.phone
    },
    {
      label: 'Miasto',
      value: office?.city
    },
    {
      label: 'Ulica',
      value: office?.street
    },
    {
      label: 'Kod pocztowy',
      value: office?.postal_code
    },
    {
      label: 'Miejscowość poczty',
      value: office?.post_office
    },
    {
      label: 'Dodano',
      value: ifContainsDateFormat(office?.created_at, formatDatetimeSeconds)
    },
    {
      label: 'Ostatnie zmiany',
      value: ifContainsDateFormat(office?.updated_at, formatDatetimeSeconds)
    }
  ];

  // if (user.hasOrganization()) {
  //   fields.push({
  //     label: 'Organizacja:',
  //     value: user.organization_name
  //   });
  // }

  // if (user.hasDepartment() && user.showDepartment()) {
  //   fields.push({
  //     label: 'Dział:',
  //     value: user.department_name
  //   });
  // }

  // if (auth && auth.hasRole(ROLE_ADMIN)) {
  //   fields.push({
  //     label: 'Data ostatniego logowania:',
  //     value: user.getLastLogin()
  //   });
  // }

  return <InformationList fields={fields} />;
};

export default BasicOfficeDataset;
