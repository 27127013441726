import _ from 'lodash';
import { ValidationErrors } from 'modules/Shared/type';
import React, { useState } from 'react';
import { Form as FormStrap } from 'reactstrap';
import { Link } from 'react-router-dom';
import { OrderEntity } from 'modules/ExecutorOrder/model/Order';
import OrderFieldset from 'modules/ExecutorOrder/component/Fieldset';
import { IFile } from 'modules/Shared/helper/Files/AddFiles';
import { serialize } from 'object-to-formdata';
import TooltipWithBtn from 'modules/Layout/component/Tooltip/WithButton';
import { DATE_FORMAT_SLASH, DATE_FORMAT_VALUE, formatDateIfExist } from 'modules/Shared/helper/utils';
import moment from 'moment';
import useWysiwyg from 'modules/Layout/component/Wysiwyg';

export interface Props {
  errors?: ValidationErrors;
  disabled?: boolean;
  officeData?: OrderEntity;
  submit: (data: OrderEntity) => void;
  cancelRedirect: string;
  isEditForm?: boolean;
}

const initPenaltiesValue = (officeData?: OrderEntity) => ({
  executor_salary: officeData.executor_salary ?? '',
  penalty_amount: officeData.penalty_amount ?? '',
  penalty_date: formatDateIfExist(officeData?.penalty_date)
});
const initTerminationValue = (officeData?: OrderEntity) => ({
  termination_date: formatDateIfExist(officeData?.termination_date)
});
const initComplaintValue = (officeData?: OrderEntity) => ({
  complaint_date: formatDateIfExist(officeData?.complaint_date),
  complaint_identifier: officeData.complaint_identifier ?? ''
});
const initMisrepresentationValue = (officeData?: OrderEntity) => ({
  exclusion_date: formatDateIfExist(officeData?.exclusion_date),
  exclusion_identifier: officeData.exclusion_identifier ?? ''
});

const Form: React.FC<Props> = ({ errors, disabled, submit, officeData = {}, cancelRedirect, isEditForm }) => {
  const [values, setValues] = useState({
    name: officeData.name ?? '',
    identifier: officeData.identifier ?? '',
    principal_name: officeData.principal_name ?? '',
    contract_number: officeData.contract_number ?? '',
    contract_place: officeData.contract_place ?? '',
    contract_value: officeData.contract_value ?? '',
    contract_date: officeData.contract_date ?? '',
    notice_number: officeData.notice_number ?? '',
    penalties_checked: officeData.penalties_checked ?? false,
    termination_checked: officeData.termination_checked ?? false,
    complaint_checked: officeData.complaint_checked ?? false,
    misrepresentation_checked: officeData.misrepresentation_checked ?? false
  });

  const { displayWysiwyg: displayPenaltiesWysiwyg, getContent: getPenaltiesContent } = useWysiwyg({
    title: 'Przyczyny naliczenia kary umownej',
    content: officeData?.penalty_description,
    tooltip: { specialSign: true }
  });
  const { displayWysiwyg: displayTerminationWysiwyg, getContent: getTerminationContent } = useWysiwyg({
    title: 'Przyczyny rozwiązania umowy',
    content: officeData?.termination_description,
    tooltip: { specialSign: true }
  });
  const { displayWysiwyg: displayComplaintDescWysiwyg, getContent: getComplaintDescContent } = useWysiwyg({
    title: 'Przyczyny złożenia pozwu',
    content: officeData?.complaint_description,
    tooltip: { specialSign: true }
  });
  const { displayWysiwyg: displayComplaintResWysiwyg, getContent: getComplaintResContent } = useWysiwyg({
    title: 'Informacje o wyniku postępowania sądowego',
    content: officeData?.complaint_result,
    tooltip: { specialSign: true }
  });
  const { displayWysiwyg: displayMisrepresentationWysiwyg, getContent: getMisrepresentationContent } = useWysiwyg({
    title: 'Przyczyny wykluczenia z postępowania',
    content: officeData?.exclusion_description,
    tooltip: { specialSign: true }
  });

  const [penaltiesValue, setPenaltiesValue] = useState(
    isEditForm ? initPenaltiesValue(officeData) : { ...initPenaltiesValue(officeData), penalties_files: [] as IFile[] }
  );

  const [terminationValue, setTerminationValue] = useState(
    isEditForm
      ? initTerminationValue(officeData)
      : { ...initTerminationValue(officeData), termination_files: [] as IFile[] }
  );

  const [complaintValue, setComplaintValue] = useState(
    isEditForm
      ? initComplaintValue(officeData)
      : {
          ...initComplaintValue(officeData),
          complaint_files: [] as IFile[]
        }
  );

  const [misrepresentationValue, setMisrepresentationValue] = useState(
    isEditForm
      ? initMisrepresentationValue(officeData)
      : {
          ...initMisrepresentationValue(officeData),
          misrepresentation_files: [] as IFile[]
        }
  );

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let submitData: any = values;

    if (values.penalties_checked) {
      const { penalty_date, ...restPenaltiesValue } = penaltiesValue;

      let penaltyDate = '';

      if (penalty_date) {
        const momentObj = moment(penalty_date, DATE_FORMAT_SLASH, true);
        if (!momentObj.isValid()) return;
        penaltyDate = momentObj.format(DATE_FORMAT_VALUE);
      }

      submitData = {
        ...submitData,
        ...restPenaltiesValue,
        penalty_description: getPenaltiesContent() || null,
        penalty_date: penaltyDate
      };
    }
    if (values.termination_checked) {
      const { termination_date, ...restTerminationValue } = terminationValue;

      let terminationDate = '';

      if (termination_date) {
        const momentObj = moment(termination_date, DATE_FORMAT_SLASH, true);
        if (!momentObj.isValid()) return;
        terminationDate = momentObj.format(DATE_FORMAT_VALUE);
      }

      submitData = {
        ...submitData,
        ...restTerminationValue,
        termination_description: getTerminationContent() || null,
        termination_date: terminationDate
      };
    }
    if (values.complaint_checked) {
      const { complaint_date, ...restComplaintValue } = complaintValue;

      let complaintDate = '';

      if (complaint_date) {
        const momentObj = moment(complaint_date, DATE_FORMAT_SLASH, true);
        if (!momentObj.isValid()) return;
        complaintDate = momentObj.format(DATE_FORMAT_VALUE);
      }

      submitData = {
        ...submitData,
        ...restComplaintValue,
        complaint_result: getComplaintResContent() || null,
        complaint_description: getComplaintDescContent() || null,
        complaint_date: complaintDate
      };
    }
    if (values.misrepresentation_checked) {
      const { exclusion_date, ...restMisrepresentationValue } = misrepresentationValue;

      let exclusionDate = '';

      if (exclusion_date) {
        const momentObj = moment(exclusion_date, DATE_FORMAT_SLASH, true);
        if (!momentObj.isValid()) return;
        exclusionDate = momentObj.format(DATE_FORMAT_VALUE);
      }

      submitData = {
        ...submitData,
        ...restMisrepresentationValue,
        exclusion_description: getMisrepresentationContent() || null,
        exclusion_date: exclusionDate
      };
    }

    if (isEditForm) {
      submit(submitData as any);
    } else {
      const { penalties_files, termination_files, complaint_files, misrepresentation_files, ...restValues } =
        submitData;

      const data = serialize(
        {
          ...restValues,
          penalties_files: (penalties_files as any[])?.map((penalties_file) => penalties_file.file) ?? null,
          termination_files: (termination_files as any[])?.map((termination_file) => termination_file.file),
          complaint_files: (complaint_files as any[])?.map((complaint_file) => complaint_file.file),
          misrepresentation_files: (misrepresentation_files as any[])?.map(
            (misrepresentation_file) => misrepresentation_file.file
          )
        },
        {
          indices: true,
          booleansAsIntegers: true,
          nullsAsUndefineds: false,
          allowEmptyArrays: false
        }
      );

      submit(data as any);
    }
  };

  const hasValue = () => !values.principal_name;
  //  const hasValue = () => !Object.values(values).some(Boolean);
  return (
    <FormStrap onSubmit={onSubmit}>
      <OrderFieldset
        disabled={disabled}
        errors={errors}
        values={values}
        setValues={setValues}
        penaltiesValue={{ ...penaltiesValue, displayPenaltiesWysiwyg }}
        setPenaltiesValue={setPenaltiesValue}
        terminationValue={{ ...terminationValue, displayTerminationWysiwyg }}
        setTerminationValue={setTerminationValue}
        complaintValue={{ ...complaintValue, displayComplaintDescWysiwyg, displayComplaintResWysiwyg }}
        setComplaintValue={setComplaintValue}
        misrepresentationValue={{ ...misrepresentationValue, displayMisrepresentationWysiwyg }}
        setMisrepresentationValue={setMisrepresentationValue}
        isEditForm={isEditForm}
      />

      <div className="d-flex justify-content-center">
        <div className="form-actions pzpeu-btn-flex">
          <TooltipWithBtn
            targetId="ExecutorOrderFormBtn"
            btnContent="Zapisz"
            tooltipContent='Wypełnij pole "Nazwa zamawiającego"'
            className="pzpeu-btn-disabled"
            wrapperClassName="pzpeu-btn-flex"
            disabled={hasValue() || disabled}
          />
          <Link to={cancelRedirect} className="btn cancel pzpeu-btn-flex waves-effect waves-light">
            Anuluj
          </Link>
        </div>
      </div>
    </FormStrap>
  );
};

export default Form;

export type IOrderFormValues = {
  name: string;
  identifier: string;
  principal_name: string;
  contract_number: string;
  contract_place: string;
  contract_value: string | number;
  contract_date: string;
  notice_number: string;
  penalties_checked: boolean;
  termination_checked: boolean;
  complaint_checked: boolean;
  misrepresentation_checked: boolean;
};

export type IPenaltiesValue = {
  executor_salary: string | number;
  penalty_amount: string | number;
  penalty_date: string;
  penalties_files?: IFile[];
  displayPenaltiesWysiwyg: () => JSX.Element;
};

export type ITerminationValue = {
  termination_date: string;
  termination_files?: IFile[];
  displayTerminationWysiwyg: () => JSX.Element;
};

export type IComplaintValue = {
  complaint_date: string;
  complaint_identifier: string;
  complaint_files?: IFile[];
  displayComplaintDescWysiwyg: () => JSX.Element;
  displayComplaintResWysiwyg: () => JSX.Element;
};

export type IMisrepresentationValue = {
  exclusion_date: string;
  exclusion_identifier: string;
  misrepresentation_files?: IFile[];
  displayMisrepresentationWysiwyg: () => JSX.Element;
};
