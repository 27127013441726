import React, { useState } from 'react';
import Listing from 'modules/Layout/component/Listing';
import { PenaltiesFileEntity } from 'modules/ExecutorOrder/model/Order';
import ViolationFilesTable from 'modules/ExecutorOrder/container/ViolationFilesTable';
import AddFilesDragDropAutoSend from 'modules/Shared/helper/Files/AddFilesDragDrop/AddFilesDragDropAutoSend';
import { Progress } from 'reactstrap';
import Loader from 'modules/Layout/component/Loader';

export interface Props {
  orderFiles: PenaltiesFileEntity[];
  orderDownloadFileRequest: (fileId: number | string) => Promise<void>;
  onDeleteFile: (file: PenaltiesFileEntity) => void;
  onAddFilesRequest: (
    section_slug: number | string,
    data: FormData,
    changeLoading: (curr: boolean) => void,
    setProgress: (value: React.SetStateAction<number>) => void
  ) => Promise<void>;
  slug: string;
}

const ViolationsFiles: React.FC<Props> = ({ onAddFilesRequest, slug, ...props }) => {
  const [progress, setProgress] = useState<number>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const sendRequest = (data: FormData) =>
    onAddFilesRequest(
      slug,
      data,
      (curr: boolean) => setLoading(curr),
      (value: number) => setProgress(value)
    );

  return (
    <div>
      <p className="h5 mr-2">Załączniki</p>
      <div className="mb-2 position-relative">
        {loading && (
          <>
            <Loader />
            <div className="position-absolute dropzone-progress-bar">
              {progress !== null && <Progress value={progress} />}
            </div>
          </>
        )}
        <AddFilesDragDropAutoSend onAddFilesRequest={sendRequest} maxSizeInMb={50} />
      </div>

      <div className="pzpeu-table-list inside-card-box mb-0">
        <Listing table={<ViolationFilesTable {...props} />} />
      </div>
    </div>
  );
};

export default ViolationsFiles;
