import { RootState } from 'app/reducer';
import ListingFilter from 'modules/Layout/component/Listing/Filter';
import { PartialSearchingProps, SearchParams } from 'modules/Shared/type';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SaveFilterModal from 'modules/Layout/component/ListFilter/SaveFilterModal';
import useSaveFilter from 'modules/Shared/helper/hooks/useSaveFilter';
import { FiltersEntity, FOREIGN_PROCEEDINGS_SLUG_FILTER } from 'modules/Layout/model/Filters';
import { createSearchingProps } from 'modules/Shared/helper/params';
import _ from 'lodash';
import useFilterValues from './FilterValues/useFilterValues';
import { setForeignProceedingsListParamsAction } from '../../../action/ForeignProceedingsList';
import ForeignProceedings from '../../../model/ForeignProceedings';

const ForeignProceedingsFavouriteFilter: React.FC = () => {
  const dispatch = useDispatch();
  const { filter, pagination } = useSelector((state: RootState) => state.foreignProceedings.foreignProceedingsList);
  const setParams = (payload: PartialSearchingProps) => dispatch(setForeignProceedingsListParamsAction(payload));
  const [filterParams, setFilterParams] = useState<SearchParams>({});

  const onSavedFilterClick = async (saveFilter: FiltersEntity) => {
    setParams({ filter: saveFilter });
  };

  const {
    saveModal,
    setSaveModal,
    cleanMessage,
    loading,
    message,
    filtersList,
    saveFilter,
    onDeleteFilterClick,
    onFilterSelectedClick: onFilterSelectedClickFn,
    filterList1stFetchFinished
  } = useSaveFilter(filter, setParams, FOREIGN_PROCEEDINGS_SLUG_FILTER, ['keywords'], {
    onFilterSelectedClickCustomFnc: onSavedFilterClick
  });

  useEffect(() => {
    if (Object.keys(filter || {}).length && !_.isEqual(filter, filterParams)) {
      setFilterParams(filter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const onSearchButtonClicked = () => setParams({ filter: filterParams });

  const onFilterSelectedClick = (id: number) => {
    setFilterParams(filter);
    setParams({ filter });
    onFilterSelectedClickFn(id);
  };

  const filteredFilterList = filtersList.filter((el) => el.filters.only_saved);

  const searchProps = createSearchingProps(
    '',
    ForeignProceedings.getFilterableAttributes(),
    ForeignProceedings.getSortableAttributes()
  );

  useEffect(() => {
    if (filterList1stFetchFinished) {
      const findDefault = filteredFilterList.find((el) => el.default);
      if (findDefault) {
        onFilterSelectedClick(findDefault.id);
      } else {
        const localStorageParams = localStorage.getItem('localFilterParamsForeignProceedingsFav');

        if (localStorageParams) {
          const parsedParams = JSON.parse(localStorageParams);

          setParams({ ...parsedParams });
        } else {
          setParams({ ...searchProps, filter: { only_saved: '1' } });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterList1stFetchFinished]);

  const { inputs } = useFilterValues({ values: filterParams });

  let disableBtn = false;

  const { only_saved: _x, ...restFilter } = filter;

  if (_.isEmpty(restFilter)) {
    disableBtn = true;
  }

  const setMessage = (value: boolean) => {
    if (message) cleanMessage();
    setSaveModal(value);
  };

  return (
    <div className="position-relative">
      <ListingFilter
        inputs={inputs}
        filter={filterParams}
        pagination={pagination}
        filtersName="foreignProceedingsFav"
        setParams={(params) => setFilterParams(params.filter)}
        saveFilterBtn={() => setMessage(true)}
        filterName="Filtrowanie"
        disableBtn={disableBtn}
        filterObj={{
          filtersList: filteredFilterList,
          onDeleteFilterClick,
          onFilterSelectedClick: onFilterSelectedClickFn,
          loading
        }}
        searchButtonObj={{
          onClick: onSearchButtonClicked,
          disabled: !Object.keys(filterParams).length,
          label: 'Filtruj'
        }}
      />
      {saveModal && (
        <SaveFilterModal
          onSaveClick={saveFilter}
          isOpen={saveModal}
          toggle={() => setMessage(false)}
          message={message}
          loading={loading}
          displayDefaultCheckbox
          shouldRefetchList
        />
      )}
    </div>
  );
};

export default ForeignProceedingsFavouriteFilter;
