import React, { useEffect, useState } from 'react';
import { RootState } from 'app/reducer';
import Listing from 'modules/Layout/component/Listing';
import { useDispatch, useSelector } from 'react-redux';
import { PageProps } from 'modules/Layout/type';
import { addToastAction, managePageAction } from 'modules/Layout/action';
import { Message, PartialSearchingProps } from 'modules/Shared/type';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import { setJudgementListParamsAction } from 'modules/Judgement/action/JudgementList';
import Judgement, { JudgementEntity } from 'modules/Judgement/model/Judgement';
import { deleteJudgment } from 'modules/Judgement/repository';
import deleteRequest from 'modules/Shared/helper/APIRequests/deleteRequest';
import GenericModalDelete from 'modules/Layout/component/Modal/GenericDeleteModal';
import { deleteJudgementToastSuccess } from 'modules/Judgement/toasts';
import JudgementTable from 'modules/Judgement/container/JudgementList/Table';
import JudgementPerPage from 'modules/Judgement/container/JudgementList/PerPage';
import JudgementListingPagination from 'modules/Judgement/container/JudgementList/Pagination';
import { ROUTE_JUDGEMENT_LIST } from 'modules/Judgement/routes';
import JudgementFilter from 'modules/Judgement/container/JudgementList/Filter';
import { breadcrumbRouteJudgementsList } from 'modules/Judgement/breadcrumbs';
import { createSearchingProps } from 'modules/Shared/helper/params';

const JudgementListView = () => {
  const [displayDeleteModal, setDisplayDeleteModal] = useState<JudgementEntity>(null);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<Message>(null);
  const dispatch = useDispatch();
  const { fetching } = useSelector((state: RootState) => state.judgement.judgementList);

  const managePage = (payload: PageProps) => dispatch(managePageAction(payload));
  const setParams = (payload: PartialSearchingProps) => dispatch(setJudgementListParamsAction(payload));

  const urlParams = new URLSearchParams(window.location.search);
  const requiresActionParam = urlParams.get('requires_action');

  const searchProps = createSearchingProps('', Judgement.getFilterableAttributes(), Judgement.getSortableAttributes());

  useEffect(() => {
    managePage({
      title: 'Orzeczenia sądowe - lista',
      breadcrumb: breadcrumbRouteJudgementsList()
    });

    if (requiresActionParam === '1') {
      setParams({ filter: { requires_action: '1' } });
    } else setParams({ ...searchProps });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dispatchDeleteJudgementToast = () => dispatch(addToastAction(deleteJudgementToastSuccess()));

  const cancelToken = useCancelToken();
  const deleteJudgementAction = async (judgment_id: number | string) => {
    setLoading(true);
    const { cancelled, message: messageRes, success } = await deleteRequest(deleteJudgment, judgment_id, cancelToken);

    if (cancelled) return;
    if (success) {
      setDisplayDeleteModal(null);
      dispatchDeleteJudgementToast();
      dispatch(setJudgementListParamsAction({}));
    }
    if (messageRes) setMessage(messageRes);
    setLoading(false);
  };

  return (
    <div className="row users-view">
      <div className="col-12">
        {!!displayDeleteModal && (
          <GenericModalDelete
            value={displayDeleteModal}
            title="Usuwanie orzeczenia"
            content={(value) => (
              <div>Czy na pewno chcesz usunąć orzeczenie o identyfikatorze - {value?.identifier}?</div>
            )}
            onDeleteClick={(value) => deleteJudgementAction(value.id)}
            isOpen={!!displayDeleteModal}
            toggle={() => setDisplayDeleteModal(null)}
            loading={loading}
            message={message}
            resetMessage={() => setMessage(null)}
          />
        )}
        <Listing
          table={<JudgementTable onDeleteClick={(value) => setDisplayDeleteModal(value)} />}
          filter={<JudgementFilter values={requiresActionParam === '1' ? { requires_action: '1' } : {}} />}
          pagination={<JudgementListingPagination path={ROUTE_JUDGEMENT_LIST} />}
          perPage={<JudgementPerPage />}
          loading={fetching}
        />
      </div>
    </div>
  );
};

export default JudgementListView;
