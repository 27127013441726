import CardBox from 'modules/Layout/component/CardBox';
import ExecutorDataClientDataset from 'modules/Executor/component/Dataset/Client/ExecutorData';
import React from 'react';
import ExecutorClient from 'modules/Executor/model/ExecutorClient';
import MainDataClientDataset from 'modules/Executor/component/Dataset/Client/MainData';
import {
  COMPLAINT_SLUG,
  MISREPRESENTATION_SLUG,
  OrderEntity,
  PENALTIES_SLUG,
  TERMINATION_SLUG
} from 'modules/ExecutorOrder/model/Order';
import ExecutorDataClientPenaltiesDataset from 'modules/Executor/component/Dataset/Client/Violations/Penalties';
import ExecutorDataClientTerminationDataset from 'modules/Executor/component/Dataset/Client/Violations/Termination';
import ExecutorDataClientMisrepresentationDataset from 'modules/Executor/component/Dataset/Client/Violations/Misrepresentation';
import ExecutorDataClientComplaintDataset from 'modules/Executor/component/Dataset/Client/Violations/Complaint';
import Disclaimer from 'modules/Executor/component/Dataset/Client/Disclaimer';
import DisplayExecutorOrderList from 'modules/Executor/container/DisplayExecutorOrderList';
import DownloadExecutorPdf from 'modules/Executor/component/DownloadPdf';
import ActionUpdate from 'modules/Layout/component/Action/Update';
import './index.scss';
import QuestionMarkIcon from 'modules/Layout/component/Icon/QuestionMark';
import DisplayPopovers from 'modules/Layout/component/Popover/DisplayWrapper';

const NO_VIOLATIONS_TEXT = `Brak danych w Raporcie, odnoszących się do danego przedsiębiorcy nie przesądza o braku wystąpienia okoliczności mogących być przedmiotem danych umieszczanych w Raporcie i nie przesądza o braku podstaw do podjęcia w stosunku do niego środków prawnych przez Użytkownika.`;

export interface Props {
  executor: ExecutorClient;
  setExecutorExplanationModal: (slug: string) => void;
}

const segregateValidations = (orders: OrderEntity[] = []) => {
  let penaltiesOrder: OrderEntity[] = [];
  let terminationOrder: OrderEntity[] = [];
  let complaintOrder: OrderEntity[] = [];
  let misrepresentationOrder: OrderEntity[] = [];

  if (orders.length)
    orders.forEach((order) => {
      if (order.penalties_checked) {
        penaltiesOrder = [...penaltiesOrder, order];
      }
      if (order.termination_checked) {
        terminationOrder = [...terminationOrder, order];
      }
      if (order.complaint_checked) {
        complaintOrder = [...complaintOrder, order];
      }
      if (order.misrepresentation_checked) {
        misrepresentationOrder = [...misrepresentationOrder, order];
      }
    });

  return { penaltiesOrder, terminationOrder, complaintOrder, misrepresentationOrder };
};

const haveAnyViolations = (
  penaltiesOrder: Array<any>,
  terminationOrder: Array<any>,
  complaintOrder: Array<any>,
  misrepresentationOrder: Array<any>
) => {
  if (
    penaltiesOrder.length != 0 ||
    terminationOrder.length != 0 ||
    complaintOrder.length != 0 ||
    misrepresentationOrder.length != 0
  )
    return false;

  return true;
};

const ExecutorDetails: React.FC<Props> = ({ executor, setExecutorExplanationModal }) => {
  const { penaltiesOrder, terminationOrder, complaintOrder, misrepresentationOrder } = segregateValidations(
    executor.orders
  );

  const getKey = (identifier: string, index: number): string => `${identifier}-${index}`;

  const haveAnyViolationsStatus = haveAnyViolations(
    penaltiesOrder,
    terminationOrder,
    complaintOrder,
    misrepresentationOrder
  );

  return (
    <div className="executor-details-client">
      <CardBox heading="Raport Asystent Postępowania">
        <p className="h3 mb-0">
          Sytuacja <span style={{ color: '#b7342e' }}>{executor?.executor_data?.name}</span> na gruncie prawa zamówień
          publicznych
        </p>
        {haveAnyViolationsStatus && (
          <div className="d-flex align-items-center mt-2 mb-0">
            <p className="h4 mr-1">Nie stwierdzono ewentualnych naruszeń</p>
            <DisplayPopovers
              popoverId={`PopoverSub1`}
              popoverContent={NO_VIOLATIONS_TEXT}
              className="tooltip-light subscriptions-tooltip-description"
            />
          </div>
        )}
      </CardBox>
      <CardBox
        heading="Informacje o zleceniu"
        paddingRight="30px"
        paddingBottom="0"
        actions={<DownloadExecutorPdf executorId={executor?.executor_data?.id} />}
      >
        <MainDataClientDataset executor={executor} />
      </CardBox>
      <CardBox heading="Dane weryfikowanego wykonawcy" paddingRight="30x" paddingBottom="0">
        <ExecutorDataClientDataset executor={executor} />
      </CardBox>
      <CardBox
        heading="Naliczenie kar umownych"
        headerElements={
          <ActionUpdate
            className="d-sm-inline d-none ml-1"
            title="Wyjaśnienie sekcji"
            label={<QuestionMarkIcon height="18px" className="question-mark-icon-executors" />}
            onClick={() => setExecutorExplanationModal(PENALTIES_SLUG)}
          />
        }
      >
        {penaltiesOrder?.length === 0 ? (
          <p key="0">Nie dotyczy</p>
        ) : (
          penaltiesOrder.map((penalty, index, arr) => (
            <div
              className={`pzpeu-table-list inside-card-box${index + 1 === arr.length ? '' : ' violations-divider'}`}
              key={getKey(penalty?.identifier, index)}
            >
              <DisplayExecutorOrderList
                orderFiles={penalty?.penalties_files}
                executorId={executor?.executor_data?.id}
                orderId={penalty?.id}
                orderListStyle={index === 0 ? {} : { paddingTop: '34px' }}
              >
                <ExecutorDataClientPenaltiesDataset penalty={penalty} />
              </DisplayExecutorOrderList>
            </div>
          ))
        )}
      </CardBox>
      <CardBox
        heading="Rozwiązanie umowy"
        headerElements={
          <ActionUpdate
            className="d-sm-inline d-none ml-1"
            title="Wyjaśnienie sekcji"
            label={<QuestionMarkIcon height="18px" className="question-mark-icon-executors" />}
            onClick={() => setExecutorExplanationModal(TERMINATION_SLUG)}
          />
        }
      >
        {terminationOrder?.length === 0 ? (
          <p key="0">Nie dotyczy</p>
        ) : (
          terminationOrder.map((termination, index, arr) => (
            <div
              className={`pzpeu-table-list inside-card-box${index + 1 === arr.length ? '' : ' violations-divider'}`}
              key={getKey(termination?.identifier, index)}
            >
              <DisplayExecutorOrderList
                orderFiles={termination?.termination_files}
                executorId={executor?.executor_data?.id}
                orderId={termination?.id}
                orderListStyle={index === 0 ? {} : { paddingTop: '34px' }}
              >
                <ExecutorDataClientTerminationDataset termination={termination} />
              </DisplayExecutorOrderList>
            </div>
          ))
        )}
      </CardBox>
      <CardBox
        heading="Wytoczenie powództwa w związku z realizacją umowy"
        headerElements={
          <ActionUpdate
            className="d-sm-inline d-none ml-1"
            title="Wyjaśnienie sekcji"
            label={<QuestionMarkIcon height="18px" className="question-mark-icon-executors" />}
            onClick={() => setExecutorExplanationModal(COMPLAINT_SLUG)}
          />
        }
      >
        {complaintOrder?.length === 0 ? (
          <p key="0">Nie dotyczy</p>
        ) : (
          complaintOrder.map((complaint, index, arr) => (
            <div
              className={`pzpeu-table-list inside-card-box${index + 1 === arr.length ? '' : ' violations-divider'}`}
              key={getKey(complaint?.identifier, index)}
            >
              <DisplayExecutorOrderList
                orderFiles={complaint?.complaint_files}
                executorId={executor?.executor_data?.id}
                orderId={complaint?.id}
                orderListStyle={index === 0 ? {} : { paddingTop: '34px' }}
              >
                <ExecutorDataClientComplaintDataset complaint={complaint} />
              </DisplayExecutorOrderList>
            </div>
          ))
        )}
      </CardBox>
      <CardBox
        heading="Wprowadzenie zamawiającego w błąd"
        headerElements={
          <ActionUpdate
            className="d-sm-inline d-none ml-1"
            title="Wyjaśnienie sekcji"
            label={<QuestionMarkIcon height="18px" className="question-mark-icon-executors" />}
            onClick={() => setExecutorExplanationModal(MISREPRESENTATION_SLUG)}
          />
        }
      >
        {misrepresentationOrder?.length === 0 ? (
          <p key="0">Nie dotyczy</p>
        ) : (
          misrepresentationOrder.map((misrepresentation, index, arr) => (
            <div
              className={`pzpeu-table-list inside-card-box${index + 1 === arr.length ? '' : ' violations-divider'}`}
              key={getKey(misrepresentation?.identifier, index)}
            >
              <DisplayExecutorOrderList
                orderFiles={misrepresentation?.misrepresentation_files}
                executorId={executor?.executor_data?.id}
                orderId={misrepresentation?.id}
                orderListStyle={index === 0 ? {} : { paddingTop: '34px' }}
              >
                <ExecutorDataClientMisrepresentationDataset misrepresentation={misrepresentation} />
              </DisplayExecutorOrderList>
            </div>
          ))
        )}
      </CardBox>
      <CardBox heading="Nota prawna" paddingRight="30px">
        <Disclaimer />
      </CardBox>
    </div>
  );
};

export default ExecutorDetails;
