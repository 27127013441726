import { RootState } from 'app/reducer';
import ListingFilter from 'modules/Layout/component/Listing/Filter';
import { PartialSearchingProps, SearchParams } from 'modules/Shared/type';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useFilterValues from './FilterValues/useFilterValues';
import { setForeignProceedingsListParamsAction } from '../../../action/ForeignProceedingsList';

export interface Props {
  values?: SearchParams;
}

const ForeignProceedingsFilter: React.FC<Props> = ({ values = {} }) => {
  const dispatch = useDispatch();
  const { filter, pagination } = useSelector((state: RootState) => state.foreignProceedings.foreignProceedingsList);
  const setParams = (payload: PartialSearchingProps) => dispatch(setForeignProceedingsListParamsAction(payload));
  const [filterParams, setFilterParams] = useState<SearchParams>({});

  const { inputs } = useFilterValues({ values });

  const onSearchButtonClicked = () => setParams({ filter: filterParams });

  useEffect(() => {
    const localStorageParams = localStorage.getItem('localFilterParamsforeign_proceedings');

    if (localStorageParams) {
      const parsedParams = JSON.parse(localStorageParams);

      setParams({ ...parsedParams });
    } else {
      setParams({ ...filterParams });
    }
  }, []);

  return (
    <ListingFilter
      inputs={inputs}
      filter={filter}
      filtersName="foreignProceedings"
      pagination={pagination}
      setParams={(params) => setFilterParams(params.filter)}
      filterName="Wyszukiwanie"
      searchButtonObj={{ onClick: onSearchButtonClicked }}
    />
  );
};

export default ForeignProceedingsFilter;
