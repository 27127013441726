import React from 'react';
import DetailsBasic from 'modules/Office/component/Details/Office/Basic';
import Office from 'modules/Office/model/Office';
import DetailsExecutorsRequestInfo from 'modules/Office/component/Details/Office/ExecutorsRequestInfo';
import DetailsExecutorsRequestList from 'modules/Office/component/Details/Office/ExecutorsRequestList';
import './index.scss';

type Props = {
  office?: Office;
  onDeleteClick?: () => void;
  showExecutorRequestReceivedModal?: (request_id: number) => void;
};

const DetailsComponent: React.FC<Props> = ({ office, onDeleteClick, showExecutorRequestReceivedModal }) => {
  return (
    <div className="col-12 office-details-component">
      <div className="row">
        <div className="col-lg-6 card-lg-wrapper-left">
          <DetailsBasic office={office} onDeleteClick={onDeleteClick} />
        </div>
        <div className="col-lg-6 card-lg-wrapper-right">
          <DetailsExecutorsRequestInfo office={office} />
        </div>
        <div className="col-lg-12">
          <DetailsExecutorsRequestList
            office={office}
            showExecutorRequestReceivedModal={showExecutorRequestReceivedModal}
          />
        </div>
      </div>
    </div>
  );
};

export default DetailsComponent;
