import _get from 'lodash/get';
import { RootState } from 'app/reducer';
import { SearchParams } from 'modules/Shared/type';
import { useSelector } from 'react-redux';
import { FilterInputType } from 'modules/Layout/type';
import {
  proceedingModeOptions,
  proceedingsDepositRequiredOptions,
  proceedingStatusOptions,
  proceedingTypeOptions
} from 'modules/Proceedings/model/Proceedings';
import { PROCEEDINGS_INIT_TYPES_VALUES } from 'modules/Proceedings/type';

export interface Props {
  proceedingsType: number;
  values?: SearchParams;
}

const useFilterValues = ({ proceedingsType, values = {} }: Props) => {
  const { filter } = useSelector((state: RootState) => state.proceedings.proceedingsList);

  const isPlanning = proceedingsType === PROCEEDINGS_INIT_TYPES_VALUES.PLANNING;

  const isDepositSelected = _get(values, 'requires_deposit') === '1';

  const inputsTimePart1 = (): FilterInputType[] => {
    const startDateFrom = _get(filter, 'start_date_from') as string;
    const startDateTo = _get(filter, 'start_date_to') as string;

    return [
      {
        beforeLineBreak: true,
        type: 'date',
        property: 'start_date_from',
        label: 'Data rozpoczęcia postępowania od',
        value: startDateFrom,
        max: String(startDateTo),
        className: 'col-6 col-lg-3 col-xl-2'
      },
      {
        type: 'date',
        property: 'start_date_to',
        label: 'Data rozpoczęcia postępowania do',
        value: startDateTo,
        min: String(startDateFrom),
        className: 'col-6 col-lg-3 col-xl-2'
      }
    ];
  };

  const inputsTimePart2 = (): FilterInputType[] => {
    if (isPlanning) return [];

    const offersSendDateFrom = _get(filter, 'offers_send_date_from') as string;
    const offersSendDateTo = _get(filter, 'offers_send_date_to') as string;

    const questionsDateFrom = _get(filter, 'questions_date_from') as string;
    const questionsDateTo = _get(filter, 'questions_date_to') as string;

    return [
      {
        type: 'date',
        property: 'offers_send_date_from',
        label: 'Termin składania ofert od',
        value: offersSendDateFrom,
        max: String(offersSendDateTo),
        className: 'col-6 col-lg-3 col-xl-2'
      },
      {
        type: 'date',
        property: 'offers_send_date_to',
        label: 'Termin składania ofert do',
        value: offersSendDateTo,
        min: String(offersSendDateFrom),
        className: 'col-6 col-lg-3 col-xl-2'
      },
      {
        type: 'date',
        property: 'questions_date_from',
        label: 'Termin zadawania pytań od',
        value: questionsDateFrom,
        max: String(questionsDateTo),
        className: 'col-6 col-lg-3 col-xl-2'
      },
      {
        type: 'date',
        property: 'questions_date_to',
        label: 'Termin zadawania pytań do',
        value: questionsDateTo,
        min: String(questionsDateFrom),
        className: 'col-6 col-lg-3 col-xl-2'
      }
    ];
  };

  const getInputs = (): FilterInputType[] => {
    return [
      {
        type: 'text',
        property: 'advance_name',
        label: 'Nazwa postępowania lub słowa kluczowe',
        value: _get(values, 'advance_name')
      },
      !isPlanning && {
        type: 'text',
        property: 'identifier',
        label: 'Numer postępowania',
        value: _get(values, 'identifier')
      },
      !isPlanning && {
        type: 'text',
        property: 'bzp_identifier',
        label: 'Numer ogłoszenia o zamówieniu w BZP',
        value: _get(values, 'bzp_identifier')
      },
      {
        type: 'select',
        property: 'type',
        label: 'Rodzaj zamówienia',
        options: [
          {
            value: '',
            label: ''
          },
          ...proceedingTypeOptions()
        ],
        value: _get(values, 'type')
      },
      {
        type: 'select',
        property: 'mode',
        label: 'Tryb udzielania zamówienia',
        options: [
          {
            value: '',
            label: ''
          },
          ...proceedingModeOptions()
        ],
        value: _get(values, 'mode')
      },
      !isPlanning && {
        type: 'select',
        property: 'status',
        label: 'Status postępowania',
        options: [
          {
            value: '',
            label: ''
          },
          ...proceedingStatusOptions()
        ],
        value: _get(values, 'status')
      },
      {
        type: 'text',
        property: 'contractor_name',
        label: 'Nazwa zamawiającego',
        value: _get(values, 'contractor_name')
      },
      !isPlanning && {
        type: 'select',
        property: 'from_bzp',
        label: 'Miejsce publikacji',
        options: [
          {
            value: '',
            label: ''
          },
          {
            value: 0,
            label: 'Asystent postępowań'
          },
          {
            value: 1,
            label: 'Biuletyn zamówień publicznych'
          }
        ],
        value: _get(values, 'from_bzp')
      },
      {
        type: 'text',
        property: 'cpv_code',
        label: 'Kod CPV',
        value: _get(values, 'cpv_code')
      },
      {
        type: 'checkbox',
        property: 'has_social_clauses',
        label: 'Pokaż tylko postępowania, które posiadają klauzule społeczne',
        value: _get(filter, 'has_social_clauses')
      },
      {
        type: 'accordion',
        property: 'accordion',
        label: 'Dodatkowe filtry',
        inputs: [
          {
            beforeLineBreak: true,
            type: 'number',
            property: 'value_min',
            label: 'Wartość zamówienia od',
            value: _get(values, 'value_min'),
            className: 'col-6 col-lg-3 col-xl-2'
          },
          {
            type: 'number',
            property: 'value_max',
            label: 'Wartość zamówienia do',
            value: _get(values, 'value_max '),
            className: 'col-6 col-lg-3 col-xl-2'
          },
          !isPlanning && {
            type: 'select',
            property: 'requires_deposit',
            label: 'Czy wadium jest wymagane',
            options: [
              {
                value: '',
                label: ''
              },
              ...proceedingsDepositRequiredOptions()
            ],
            value: _get(values, 'requires_deposit')
          },
          !isPlanning && {
            type: 'number',
            property: 'deposit_min',
            label: 'Wartość wadium od',
            value: isDepositSelected ? _get(filter, 'deposit_min') : '',
            disabled: !isDepositSelected,
            className: 'col-6 col-lg-3 col-xl-2'
          },
          !isPlanning && {
            type: 'number',
            property: 'deposit_max',
            label: 'Wartość wadium do',
            value: isDepositSelected ? _get(filter, 'deposit_max') : '',
            disabled: !isDepositSelected,
            className: 'col-6 col-lg-3 col-xl-2'
          },
          ...inputsTimePart1(),
          ...inputsTimePart2(),
          {
            beforeLineBreak: true,
            type: 'text',
            property: 'contractor_address',
            label: 'Adres zamawiającego',
            value: _get(values, 'contractor_address')
          },
          {
            type: 'text',
            property: 'contractor_email',
            label: 'Email zamawiającego',
            value: _get(values, 'contractor_email')
          },
          {
            type: 'text',
            property: 'contractor_nip',
            label: 'NIP zamawiającego',
            value: _get(values, 'contractor_nip')
          }
        ]
      } as FilterInputType
    ];
  };

  return { inputs: getInputs() };
};

export default useFilterValues;
