import Table, { TableCol } from 'modules/Layout/component/Table';
import React from 'react';
import Authorize from 'modules/Auth/container/Authorize';
import ActionDelete from 'modules/Layout/component/Action/Delete';
import ActionUpdate from 'modules/Layout/component/Action/Update';
import { formatDatetimeSeconds, ifContainsDateFormat } from 'modules/Shared/helper/utils';
import EditIcon from 'modules/Layout/component/Icon/Edit';
import { ForeignProceedingsCPVValues } from '../../type';
import { FOREIGN_PROCEEDINGS_DELETE, FOREIGN_PROCEEDINGS_EDIT } from '../../permissions';

type Props = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  SAOSKeywords: ForeignProceedingsCPVValues[];
  onDeleteClick: (SAOSKeyword: ForeignProceedingsCPVValues) => void;
  onEditClick: (SAOSKeyword: ForeignProceedingsCPVValues) => void;
  // eslint-disable-next-line react/require-default-props
  isAdmin?: boolean;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const CPVTable: React.FC<Props> = ({ SAOSKeywords, onEditClick, onDeleteClick, isAdmin }) => {
  const cols: TableCol<ForeignProceedingsCPVValues>[] = [
    {
      property: 'code',
      label: 'Kod'
    },
    {
      property: 'name',
      label: 'Nazwa'
    },
    {
      property: 'created_at',
      label: 'Utworzono',
      value: (row) => ifContainsDateFormat(row.created_at, formatDatetimeSeconds)
    },
    {
      property: 'updated_at',
      label: 'Zaktualizowano',
      value: (row) => ifContainsDateFormat(row.updated_at, formatDatetimeSeconds)
    }
  ];

  const actionCols: TableCol<ForeignProceedingsCPVValues> = {
    property: 'actions',
    label: 'Akcje',
    value: function Actions(row) {
      return (
        <div className="actions-wrapper">
          {onEditClick && (
            <Authorize permissions={[FOREIGN_PROCEEDINGS_EDIT]}>
              <ActionUpdate
                className="mx-1"
                title="Edycja kodu CPV"
                label={<EditIcon height="20px" />}
                onClick={() => onEditClick(row)}
              />
            </Authorize>
          )}
          {onDeleteClick && (
            <Authorize permissions={[FOREIGN_PROCEEDINGS_DELETE]}>
              <ActionDelete className="mx-1" title="Usuń kod CPV" onClick={() => onDeleteClick(row)} />
            </Authorize>
          )}
        </div>
      );
    }
  };

  const finalCols = isAdmin ? [...cols, actionCols] : cols;

  return <Table cols={finalCols} rows={SAOSKeywords} />;
};

export default CPVTable;
