import { Routes } from 'app/routes';
import Authorize from 'modules/Auth/container/Authorize';
import { PAYMENY_HISTORY_ALL, USERS_ADD, USERS_EDIT, USERS_INDEX, USER_DETAILS } from 'modules/User/permissions';
import { Users, UsersAdmin, UsersDeleted } from 'modules/User/view';
import Details from 'modules/User/view/Details';
import UserCreateView from 'modules/User/view/Create';
import React from 'react';
import { Route } from 'react-router-dom';
import UserBasicUpdateView from 'modules/User/view/Update/Basic';
import UserCompanyUpdateView from 'modules/User/view/Update/Company';
import PaymentLogsDetailsView from 'modules/User/view/Details/PaymentLogsDetails';
import InvoicesRequiredView from 'modules/User/view/InvoiceRequired';

export const ROUTE_USERS = '/users';
export const ROUTE_USERS_ADMINS = '/users/admins';
export const ROUTE_USERS_DELETED = '/users/deleted';
export const ROUTE_USER = '/users/:id(\\d+)';
export const ROUTE_USER_CREATE = '/users/create';
export const ROUTE_USER_ADMINS_CREATE = '/users/admins/create';
export const ROUTE_USER_BASIC_UPDATE = '/users/basic/update/:id(\\d+)';
export const ROUTE_USER_COMPANY_UPDATE = '/users/company/update/:id(\\d+)';
export const ROUTE_USER_PAYMENT_LOG = '/users/:user_id(\\d+)/payment/:payment_id';

export const ROUTE_USER_MODULE_UPDATE = '/users/module/update/:id(\\d+)';
export const ROUTE_USER_ROLE_UPDATE = '/users/role/update/:id(\\d+)';

export const ROUTE_INVOICES = '/invoices';

export const createUserRoutes = (): Routes => ({
  wrapped: [
    <Authorize key="users" permissions={[USERS_INDEX]}>
      <Route path={ROUTE_USERS} exact component={Users} />
    </Authorize>,
    <Authorize key="users-admins" permissions={[USERS_INDEX]}>
      <Route path={ROUTE_USERS_ADMINS} exact component={UsersAdmin} />
    </Authorize>,
    <Authorize key="users-deleted" permissions={[USERS_INDEX]}>
      <Route path={ROUTE_USERS_DELETED} exact component={UsersDeleted} />
    </Authorize>,
    <Authorize key="user" permissions={[USER_DETAILS]}>
      <Route path={ROUTE_USER} exact component={Details} />
    </Authorize>,
    <Authorize key="user-create" permissions={[USERS_ADD]}>
      <Route path={ROUTE_USER_CREATE} exact component={UserCreateView} />
    </Authorize>,
    <Authorize key="user-admins-create" permissions={[USERS_ADD]}>
      <Route path={ROUTE_USER_ADMINS_CREATE} exact render={() => <UserCreateView isAdminCreate />} />
    </Authorize>,
    <Authorize key="user-update" permissions={[USERS_EDIT]}>
      <Route
        path={ROUTE_USER_BASIC_UPDATE}
        exact
        render={(props) => <UserBasicUpdateView params={props.match.params} />}
      />
      <Route
        path={ROUTE_USER_COMPANY_UPDATE}
        exact
        render={(props) => <UserCompanyUpdateView params={props.match.params} />}
      />
    </Authorize>,
    <Authorize key="user-payment-log" permissions={[PAYMENY_HISTORY_ALL]}>
      <Route path={ROUTE_USER_PAYMENT_LOG} exact component={PaymentLogsDetailsView} />
    </Authorize>,
    <Authorize key="invoices-required" permissions={[PAYMENY_HISTORY_ALL]}>
      <Route path={ROUTE_INVOICES} exact component={InvoicesRequiredView} />
    </Authorize>
  ]
});
