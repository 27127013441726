import React from 'react';
import InformationList from 'modules/Layout/component/InformationList';
import { OrderEntity } from 'modules/ExecutorOrder/model/Order';
import { formatDate, ifContainsDateFormat } from 'modules/Shared/helper/utils';

export interface Props {
  penalty?: OrderEntity;
}

const ExecutorDataClientPenaltiesDataset: React.FC<Props> = ({ penalty = {} }) => {
  const fields = [
    {
      label: 'Podmiot udzielający informacji publicznej (zamawiający)',
      value: <strong>{penalty.principal_name || 'nie dotyczy'}</strong>
    },
    {
      label: 'Nr umowy o udzielenie zamówienia',
      value: penalty.contract_number || 'nie dotyczy'
    },
    {
      label: 'Wysokość wynagrodzenia wykonawcy',
      value: penalty.executor_salary ? `${penalty.executor_salary.toFixed(2).replace('.', ',')} PLN` : 'nie dotyczy'
    },
    {
      label: 'Wysokość kary umownej',
      value: penalty.penalty_amount ? `${penalty.penalty_amount.toFixed(2).replace('.', ',')} PLN` : 'nie dotyczy'
    },
    {
      label: 'Data naliczenia kary umownej',
      value: ifContainsDateFormat(penalty.penalty_date, formatDate) || 'nie dotyczy'
    },
    {
      label: 'Nazwa oraz numer postępowania',
      value:
        penalty.name || penalty.identifier
          ? `${penalty.name || '-'}; numer postępowania: ${penalty.identifier || '-'}`
          : 'nie dotyczy'
    },
    {
      label: 'Przyczyny naliczenia kar umownych',
      value: penalty.penalty_description || 'nie dotyczy',
      html: true
    }
  ];

  return <InformationList fields={fields} />;
};

export default ExecutorDataClientPenaltiesDataset;
// import React from 'react';
// import InformationList from 'modules/Layout/component/InformationList';
// import { OrderEntity } from 'modules/ExecutorOrder/model/Order';

// export interface Props {
//   penalties?: OrderEntity[];
// }

// const ExecutorDataClientPenaltiesDataset: React.FC<Props> = ({ penalties = [] }) => {
//   const fields = (penaltyValue: OrderEntity) => [
//     {
//       label: 'Podmiot udzielający informacji publicznej (zamawiający):',
//       value: penaltyValue.principal_name || 'nie dotyczy'
//     },
//     {
//       label: 'Nr umowy o udzielenie zamówienia:',
//       value: penaltyValue.contract_number || 'nie dotyczy'
//     },
//     {
//       label: 'Wysokość wynagrodzenia wykonawcy:',
//       value: `${`${penaltyValue.executor_salary?.toFixed(2)} PLN`}` || 'nie dotyczy'
//     },
//     {
//       label: 'Wysokość kary umownej:',
//       value: `${`${penaltyValue.penalty_amount?.toFixed(2)} PLN`}` || 'nie dotyczy'
//     },
//     {
//       label: 'Data naliczenia kary umownej:',
//       value: penaltyValue.penalty_date || 'nie dotyczy'
//     },
//     {
//       label: 'Nazwa oraz numer postępowania:',
//       value: penaltyValue.identifier || 'nie dotyczy'
//     },
//     {
//       label: 'Przyczyny naliczenia kar umownych:',
//       value: penaltyValue.penalty_description || 'nie dotyczy',
//       html: true
//     }
//   ];

//   if (!penalties.length) return <p>Nie dotyczy</p>;

//   return (
//     <>
//       {penalties.map((penalty) => (
//         <div className="border-except-last" key={penalty.identifier}>
//           <div className="h4 mb-2">Nazwa postępowania - {penalty.name}</div>
//           <InformationList fields={fields(penalty)} />
//         </div>
//       ))}
//     </>
//   );
// };

// export default ExecutorDataClientPenaltiesDataset;
