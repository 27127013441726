import { RootState } from 'app/reducer';
import _ from 'lodash';
import { setSearchingParamsAction, SetSearchingParamsAction } from 'modules/Shared/action';
import Validation from 'modules/Shared/exception/Validation';
import { createSearchParams } from 'modules/Shared/helper/params';
import { PaginationMeta, PartialSearchingProps } from 'modules/Shared/type';
import { UserEntity } from 'modules/User/model/User';
import { fetchUsers } from 'modules/User/repository';
import { Action, Dispatch } from 'redux';

export const SET_USER_LIST_PARAMS = 'USER/LIST/PARAMS/SET';
export const USER_LIST = 'USER/LIST';

export type UserListAction = SetUserListParamsAction | UserListFetchedAction;

export type SetUserListParamsAction = SetSearchingParamsAction<typeof SET_USER_LIST_PARAMS>;

export type UserListFetchedPayload = {
  users: UserEntity[];
  meta: PaginationMeta;
};
export interface UserListFetchedAction extends Action<typeof USER_LIST> {
  payload: UserListFetchedPayload;
}
export const setUserListParamsAction =
  (payload: PartialSearchingProps) => async (dispatch: Dispatch, getState: () => RootState) => {
    const currentState = getState()?.user?.list;

    if (
      payload?.filter &&
      !payload?.pagination &&
      typeof currentState?.pagination?.page === 'number' &&
      currentState.pagination.page !== 1 &&
      !_.isEqual(currentState.filter, payload.filter)
    ) {
      currentState.pagination.page = 1;
    }

    const state = { ...currentState, ...payload };

    dispatch(setSearchingParamsAction(SET_USER_LIST_PARAMS, payload));
    try {
      const { pagination, filter, sort } = state;

      const searchParams = createSearchParams({ pagination, filter, sort });

      const response = await fetchUsers(searchParams);

      const { data, meta } = response.data;

      dispatch<UserListFetchedAction>({
        type: USER_LIST,
        payload: { users: data, meta }
      });
    } catch (error) {
      if (error instanceof Validation) {
        dispatch<UserListFetchedAction>({
          type: USER_LIST,
          payload: { users: [], meta: null }
        });
      } else {
        throw error;
      }
    }
  };
