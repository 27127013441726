import React, { useState } from 'react';
import { RootState } from 'app/reducer';
import Listing from 'modules/Layout/component/Listing';
import { useDispatch, useSelector } from 'react-redux';
import { addToastAction } from 'modules/Layout/action';
import { Message } from 'modules/Shared/type';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import deleteRequest from 'modules/Shared/helper/APIRequests/deleteRequest';
import GenericModalDelete from 'modules/Layout/component/Modal/GenericDeleteModal';
import ForeignProceedings from '../../../model/ForeignProceedings';
import { setForeignProceedingsListParamsAction } from '../../../action/ForeignProceedingsList';
import { deleteForeignProceedings } from '../../../repository';
import { deleteForeignProceedingsCategoryToastSuccess } from '../../../toasts';
// eslint-disable-next-line import/no-cycle
import ForeignProceedingsTable from '../../../container/ForeignProceedingsList/Table';
import ForeignProceedingsFilter from '../../../container/ForeignProceedingsList/Filter';
import ForeignProceedingsListingPagination from '../../../container/ForeignProceedingsList/Pagination';
import ForeignProceedingsPerPage from '../../../container/ForeignProceedingsList/PerPage';

const useAdminForeignProceedingsList = (foreignProceedingsRoute: string) => {
  const dispatch = useDispatch();
  const { fetching } = useSelector((state: RootState) => state.foreignProceedings.foreignProceedingsList);
  const [displayModal, setDisplayModal] = useState<ForeignProceedings>(null);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<Message>(null);

  const dispatchDeleteForeignProceedingsToast = () =>
    dispatch(addToastAction(deleteForeignProceedingsCategoryToastSuccess()));

  const cancelToken = useCancelToken();
  const deleteForeignProceedingsAction = async (judgment_id: number | string) => {
    setLoading(true);
    const {
      cancelled,
      message: messageRes,
      success
    } = await deleteRequest(deleteForeignProceedings, judgment_id, cancelToken);

    if (cancelled) return;
    if (success) {
      setDisplayModal(null);
      dispatchDeleteForeignProceedingsToast();
      dispatch(setForeignProceedingsListParamsAction({}));
    }
    if (messageRes) setMessage(messageRes);
    setLoading(false);
  };

  const jsxFnc = () => (
    <div className="row users-view">
      <div className="col-12">
        {!!displayModal && (
          <GenericModalDelete
            value={displayModal}
            title="Usuwanie postępowania"
            content={(value) => <div>Czy na pewno chcesz usunąć postępowanie o nazwie - {value?.name}?</div>}
            onDeleteClick={(value) => deleteForeignProceedingsAction(value.id)}
            isOpen={!!displayModal}
            toggle={() => setDisplayModal(null)}
            loading={loading}
            message={message}
            resetMessage={() => setMessage(null)}
          />
        )}
        <Listing
          table={
            <ForeignProceedingsTable
              isAdmin
              onDeleteClick={(foreignProceedings) => setDisplayModal(foreignProceedings)}
            />
          }
          filter={<ForeignProceedingsFilter />}
          pagination={<ForeignProceedingsListingPagination path={foreignProceedingsRoute} />}
          perPage={<ForeignProceedingsPerPage />}
          loading={fetching}
        />
      </div>
    </div>
  );

  return { jsxFnc };
};

export default useAdminForeignProceedingsList;
