export const EXECUTOR_SLUG_FILTER = 'executors';
export const CLAUSE_SLUG_FILTER = 'clauses';
export const JUDGMENT_SLUG_FILTER = 'judgments';
export const PROCEEDINGS_SLUG_FILTER = 'proceedings';
export const FOREIGN_PROCEEDINGS_SLUG_FILTER = 'foreign_proceedings';
export const AGREEMENTS_SLUG_FILTER = 'agreements';

export type FiltersEntity = {
  [key: string]: string | number;
};

export type FilterEntity = {
  id?: number;
  slug?: string;
  name?: string;
  default?: boolean;
  filters?: FiltersEntity;
  updated_at?: string;
  created_at?: string;
};

export default class Filter {
  readonly id?: number;
  readonly slug?: string;
  readonly name?: string;
  readonly default?: boolean;
  readonly filters?: FiltersEntity;
  readonly updated_at?: string;
  readonly created_at?: string;

  constructor(entity: FilterEntity) {
    this.id = entity.id;
    this.slug = entity.slug;
    this.name = entity.name;
    this.default = entity.default;
    this.filters = entity.filters;
    this.updated_at = entity.updated_at;
    this.created_at = entity.created_at;
  }
}

export const createFilter = (entity: FilterEntity): Filter => new Filter(entity);
