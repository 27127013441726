import React, { ReactNode } from 'react';
import './style.scss';

export interface InformationField {
  label: string;
  value: ReactNode;
  className?: string;
  valueClassName?: string;
  valueClassNameParent?: string;
  afterValue?: ReactNode;
  newLineValue?: ReactNode;
  html?: boolean;
  htmlFieldInsertedStyle?: Record<any, any>;
  listItemClassName?: string;
}

export interface InformationListProps {
  fields: InformationField[];
  children?: ReactNode;
}

const InformationList: React.FC<InformationListProps> = ({ fields, children }: InformationListProps): JSX.Element => {
  const paragraphs = fields.map((field, index) => {
    const key = `information-list-item-${index}`;

    if (field.value) {
      if (field.html) {
        return (
          <div
            key={key}
            className={`information-list-item${field?.listItemClassName ? ` ${field.listItemClassName}` : ''}`}
          >
            <div className="item-value w-100">
              <div
                className={`${field.valueClassName ?? 'information-list-html'}`}
                style={field?.htmlFieldInsertedStyle ?? {}}
                dangerouslySetInnerHTML={{ __html: String(field.value) }}
              />
              {Boolean(field.afterValue) && field.afterValue}
            </div>
            {field.label && (
              <p className="information-list-paragraph w-100">
                <span className="item-label">{field.label}</span>
              </p>
            )}
          </div>
        );
      }

      return (
        <div
          key={key}
          className={`information-list-item${field?.listItemClassName ? ` ${field.listItemClassName}` : ''}`}
        >
          <div className={`information-list-paragraph ${field.valueClassNameParent ?? ''}`}>
            <div className="item-value w-100">
              <span className={`${field.valueClassName ?? 'value'}`}>{field.value}</span>
              {Boolean(field.afterValue) && field.afterValue}
            </div>
            {field.label && <div className="item-label w-100">{field.label}</div>}
          </div>
          {field.newLineValue}
        </div>
      );
    }

    return null;
  });

  return (
    <div className="information-list-wrapper">
      {children}
      {paragraphs}
    </div>
  );
};

export default InformationList;
