import React from 'react';
import Order, {
  COMPLAINT_SLUG,
  MISREPRESENTATION_SLUG,
  PenaltiesFileEntity,
  PENALTIES_SLUG,
  TERMINATION_SLUG
} from 'modules/ExecutorOrder/model/Order';
import PenaltiesProblem from 'modules/ExecutorOrder/component/Dataset/Violations/Penalties';
import TerminationProblem from 'modules/ExecutorOrder/component/Dataset/Violations/Termination';
import ComplainProblem from 'modules/ExecutorOrder/component/Dataset/Violations/Complaint';
import MisrepresentationProblem from 'modules/ExecutorOrder/component/Dataset/Violations/Misrepresentation';
import Loader from 'modules/Layout/component/Loader';
import ViolationsFiles from 'modules/ExecutorOrder/component/Dataset/Violations/ViolationsFiles';
import './index.scss';

interface Props {
  order?: Order;
  orderDownloadFileRequest: (fileId: number | string) => Promise<void>;
  onDeleteFile: (file: PenaltiesFileEntity) => void;
  loadingFile: boolean;
  onAddFilesRequest: (
    section_slug: number | string,
    data: FormData,
    changeLoading: (curr: boolean) => void,
    setProgress: (value: React.SetStateAction<number>) => void
  ) => Promise<void>;
}

type IDisplayViolation = {
  title: string;
  problem: React.ReactElement;
  fileProblem: React.ReactElement;
};

const displayViolation = ({ title, problem, fileProblem }: IDisplayViolation) => (
  <div className="order-violations-item">
    <div className="mb-3">
      <div className="d-flex flex-wrap justify-content-between mr-2">
        <div className="h4">{title}</div>
      </div>
      {problem}
    </div>
    {fileProblem}
  </div>
);

const ViolationWrapper: React.FC<Props> = ({ order, loadingFile, ...rest }) => {
  return (
    <div className="order-violations-wrapper">
      {loadingFile && <Loader />}
      {order?.penalties_checked &&
        displayViolation({
          title: 'Kary umowne',
          problem: <PenaltiesProblem order={order} />,
          fileProblem: <ViolationsFiles orderFiles={order.penalties_files} slug={PENALTIES_SLUG} {...rest} />
        })}
      {order?.termination_checked &&
        displayViolation({
          title: 'Rozwiązanie umowy',
          problem: <TerminationProblem order={order} />,
          fileProblem: <ViolationsFiles orderFiles={order.termination_files} slug={TERMINATION_SLUG} {...rest} />
        })}
      {order?.complaint_checked &&
        displayViolation({
          title: 'Wytoczenie powództwa',
          problem: <ComplainProblem order={order} />,
          fileProblem: <ViolationsFiles orderFiles={order.complaint_files} slug={COMPLAINT_SLUG} {...rest} />
        })}
      {order?.misrepresentation_checked &&
        displayViolation({
          title: 'Wprowadzenie zamawiającego w błąd',
          problem: <MisrepresentationProblem order={order} />,
          fileProblem: (
            <ViolationsFiles orderFiles={order.misrepresentation_files} slug={MISREPRESENTATION_SLUG} {...rest} />
          )
        })}
      {!order?.penalties_checked &&
        !order?.termination_checked &&
        !order?.complaint_checked &&
        !order?.misrepresentation_checked && <div className="h5">Brak</div>}
    </div>
  );
};

export default ViolationWrapper;
