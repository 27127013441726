import React, { useEffect, useState } from 'react';
import WysiwygComponent, { MenuBarOptions } from 'modules/Layout/component/Wysiwyg/WysiwygComponent';
import { styleEmptyNewLine } from 'modules/Shared/helper/manageEditorStateWysiwyg';
import './style.scss';

type WysiwygProps = {
  content?: string;
  title?: string;
  required?: boolean;
  tooltip?: MenuBarOptions;
  isInvalid?: boolean;
};

const useWysiwyg = (obj: WysiwygProps = {}) => {
  const { content = '', isInvalid } = obj;
  const [editorState, setEditorState] = useState(content);

  useEffect(() => {
    if (content !== editorState) {
      setEditorState(content);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content]);

  const getContent = () => {
    if (editorState) return styleEmptyNewLine(editorState);
    return null;
  };

  const resetContent = () => {
    setEditorState(null);
  };

  const displayWysiwyg = () => (
    <>
      <WysiwygComponent {...obj} setContent={setEditorState} isInvalid={isInvalid} />
    </>
  );

  return {
    displayWysiwyg,
    getContent,
    resetContent
  };
};

export default useWysiwyg;
